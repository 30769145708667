import { createAsyncThunk } from '@reduxjs/toolkit';
import { validateCharterPhoneNumber } from '@services/requests/phoneNumbers';
import { BUFFER_PHONE_NUMBERS } from '@store/slices/resources';

export const ThunkValidateCharterPhone = createAsyncThunk(
  `${BUFFER_PHONE_NUMBERS}/validateCharterPhone`,
  async phoneInfo => validateCharterPhoneNumber(phoneInfo),
);

export default {};
