import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  MessagingConfigurationSelector,
  MessagingChannelSelector,
  MessagingChannelLogsSelector,
  MessagingChannelCrmInfoSelector,
  setTemplateText,
} from '@store/slices/messaging';
import {
  ThunkDeleteTechnicianNumber,
  ThunkGetConversationTemplates,
} from '@store/slices/messaging/thunks';

import { MESSAGING_CHANNELS } from '@constants/routes';
import getFormattedDate from '@utils/getFormattedDate';

import DeleteIcon from '@assets/icons/delete.svg';
import AddIcon from '@assets/icons/plus.svg';
import SearchableMenu from '@ui/components/common/SearchableMenu';
import ReusableButton from '@ui/components/common/Button/Button';
import LogsDialog from '@ui/components/Triggers/pages/Incident/components/dialogs/LogsDialog';
import ActionFieldItem from '@ui/components/common/ActionButtons/ActionFieldItem';
import ConfirmDialog from '@ui/components/common/ConfirmDialog/ConfirmDialog';
import DeleteChannelDialog from '../dialogs/DeleteChannelDialog';
import ChannelDetailsItem from './ChannelDetailsItem';
import ContactsDialog from '../dialogs/ContactsDialog';
import AddTechnicianNumberDialog from '../dialogs/AddTechnicianNumberDialog';

import useStyles from '../styles';

const ChannelDetails = React.memo(() => {
  const [openLogsDialog, setOpenLogsDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openContactsDialog, setOpenContactsDialog] = useState(false);
  const [conversationTemplates, setConversationTemplates] = useState([]);
  const [openDeleteTechnicianDialog, setOpenDeleteTechnicianDialog] = useState(
    false,
  );
  const [openAddTechnicianDialog, setOpenAddTechnicianDialog] = useState(false);
  const [processing, setProcessing] = useState(false);

  const classes = useStyles();
  const { push } = useHistory();
  const dispatch = useDispatch();

  const configuration = useSelector(MessagingConfigurationSelector);
  const channel = useSelector(MessagingChannelSelector);
  const crmInfo = useSelector(MessagingChannelCrmInfoSelector);

  const handleTechnicianDelete = useCallback(async () => {
    setProcessing(true);
    await dispatch(ThunkDeleteTechnicianNumber(channel.id));
    setProcessing(false);
    setOpenDeleteTechnicianDialog(false);
  }, [channel, dispatch]);

  const handleMessageSend = useCallback(
    item => {
      dispatch(
        setTemplateText(
          item.text
            .replace(
              // eslint-disable-next-line no-template-curly-in-string
              '${contactName}',
              crmInfo?.contactName ?? '',
            )
            .replace(
              // eslint-disable-next-line no-template-curly-in-string
              '${ticketId}',
              crmInfo.ticketId ?? '',
            )
            .replace(
              // eslint-disable-next-line no-template-curly-in-string
              '${companyName}',
              crmInfo.companyName ?? '',
            ),
        ),
      );
    },
    [dispatch, crmInfo],
  );

  const handleBackClick = () => {
    push(MESSAGING_CHANNELS);
  };

  const onMount = useCallback(async () => {
    await dispatch(ThunkGetConversationTemplates())
      .unwrap()
      .then(res => {
        setConversationTemplates(res);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <div className={classes.boxExtended}>
      <ReusableButton label="Back" size="sm" onClick={handleBackClick} />

      <div className={classes.boxHeader}>
        Channel with
        {` ${channel.userNumber}`}
      </div>
      <div>
        <ChannelDetailsItem
          label="Contact"
          value={channel.userName}
          isEditable
        />
        <ChannelDetailsItem
          label="Configuration Name"
          value={configuration.name}
        />
        <ChannelDetailsItem
          label="Configuration PSA"
          value={configuration.crmName}
        />
        <ChannelDetailsItem
          label="Status"
          value={channel.isClosed ? 'Closed' : 'Open'}
        />
        <ChannelDetailsItem label="Main Number" value={channel.mainNumber} />
        <ChannelDetailsItem
          label="Channel Number"
          value={channel.channelNumber}
        />
        <ChannelDetailsItem
          label="Created At:"
          value={getFormattedDate(channel.createdAt)}
        />
        {channel.isClosed && (
          <ChannelDetailsItem
            label="Closed At:"
            value={getFormattedDate(channel.closedAt)}
          />
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ChannelDetailsItem
            label="Technician"
            value={
              channel.techName
                ? `${channel.techName} [${channel.techPhone}]`
                : null
            }
          />
          {channel.techPhone ? (
            <ActionFieldItem
              icon={DeleteIcon}
              alt="deletePhone"
              toolTip="Delete Phone"
              handler={() => {
                setOpenDeleteTechnicianDialog(true);
              }}
            />
          ) : (
            <ActionFieldItem
              icon={AddIcon}
              alt="editPhone"
              toolTip="Add Phone"
              handler={() => {
                setOpenAddTechnicianDialog(true);
              }}
            />
          )}
        </div>

        <div className={classes.detailsActions}>
          {!channel?.isClosed && (
            <ReusableButton
              label="Close Channel"
              size="md"
              onClick={() => {
                setOpenDeleteDialog(true);
              }}
            />
          )}

          <ReusableButton
            label="Channel Logs"
            size="md"
            onClick={() => {
              setOpenLogsDialog(true);
            }}
          />

          <SearchableMenu
            menuItems={conversationTemplates}
            onMenuItemClick={handleMessageSend}
            buttonLabel="Message Templates"
            buttonSize="xl"
            searchable
          />

          <ReusableButton
            label="Contacts"
            size="md"
            onClick={() => {
              setOpenContactsDialog(true);
            }}
          />
        </div>
      </div>

      {openLogsDialog && (
        <LogsDialog
          open={openLogsDialog}
          setOpen={setOpenLogsDialog}
          selector={MessagingChannelLogsSelector}
        />
      )}

      {openDeleteDialog && channel && configuration && (
        <DeleteChannelDialog
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          channelIds={[channel.id]}
          configurationId={configuration.id}
          crmType={configuration.crmType}
          fromChannelPage
        />
      )}

      {openContactsDialog && (
        <ContactsDialog
          open={openContactsDialog}
          setOpen={setOpenContactsDialog}
          crmDefaultId={configuration.crmDefaultId}
        />
      )}

      {openDeleteTechnicianDialog && (
        <ConfirmDialog
          open={openDeleteTechnicianDialog}
          setOpen={setOpenDeleteTechnicianDialog}
          onCancel={() => {
            setOpenDeleteTechnicianDialog(false);
          }}
          title="Confirm Action"
          content="Are you sure you want to remove technician number?"
          loading={processing}
          onAccept={handleTechnicianDelete}
        />
      )}

      {openAddTechnicianDialog && (
        <AddTechnicianNumberDialog
          open={openAddTechnicianDialog}
          setOpen={setOpenAddTechnicianDialog}
        />
      )}
    </div>
  );
});

ChannelDetailsItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};
ChannelDetails.propTypes = {};

export default ChannelDetails;
