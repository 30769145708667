import React, { useState } from 'react';
import { useAuthProvider, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import deleteWithResponse from '@services/api/common/deleteWithResponse';
import { actions } from '@store/actions';
import appConfig from '@configs/appConfig';
import { listSelector } from '@store/selectors';

import AssignDialog from '@components/Audit/dialogs/AssignDialog';
import deleteIcon from '@assets/icons/deleteWhite.svg';
import iconUser from '@assets/icons/user.svg';
import ReusableButton from '@common/Button/Button';
import {
  addAssignee,
  toggleAcknowledge,
} from '@components/Ticketing/Integrations/helpers';
import iconShieldDone from '@assets/icons/shield_done.svg';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import AckDialog from '@components/Audit/dialogs/AckDialog';
import useStyles from './styles';

import { useTableContext } from '../../../../hooks/table';

const BulkActionButtons = props => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const classes = useStyles();
  const translate = useTranslate();
  const authProvider = useAuthProvider();
  const [ackDialogOpen, setAckDialogOpen] = useState(false);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const list = useSelector(listSelector);

  const { selectedIds, deselectAllItems } = useTableContext();
  const {
    url,
    resource,
    users,
    showAssignment,
    excludeFromIdList,
    dataViewId,
  } = props;

  const onDeleteHandler = () => {
    setDeleteDialogOpen(false);
    const filteredIdList = excludeFromIdList
      ? selectedIds.filter(id => id !== excludeFromIdList)
      : [...selectedIds];
    const params = filteredIdList.map(i => `id=${i}`).join('&');
    const queryUrl = url || `/${resource}/`;
    deleteWithResponse(`${appConfig.baseUrl}${queryUrl}BulkDelete?${params}`)
      .then(() => {
        enqueueSnackbar(`Successfully removed`, { variant: 'success' });
        dispatch(actions.removeItemFromList({ selectedIds }));
        deselectAllItems();
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  };

  const handleAttachmentAction = (key, id) => {
    const userName = users.find(item => item.id === id)?.name;
    return list.map(item =>
      selectedIds.includes(item.id) ? { ...item, [key]: userName } : item,
    );
  };

  const handleAssignDialogSelectValue = value => {
    addAssignee(value, selectedIds, dataViewId)
      .then(() => {
        const newList = handleAttachmentAction('assignment', value);
        dispatch(actions.saveList(newList));
        enqueueSnackbar('Successfully assigned', { variant: 'success' });
        deselectAllItems();
        setAssignDialogOpen(false);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  };

  const handleAddAcknowledgeSelectValue = async value => {
    const payload = {
      activate: true,
      policyIds: [...selectedIds],
      ackType: value,
    };
    try {
      await toggleAcknowledge(payload);
      const newList = handleAttachmentAction(
        'acknowledge',
        authProvider.getUserId(),
      );
      dispatch(actions.saveList(newList));
      setAckDialogOpen(false);
      enqueueSnackbar('Done', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  return (
    <>
      {showAssignment && (
        <>
          <ReusableButton
            onClick={() => setAckDialogOpen(true)}
            classNameWrapper={cx(classes.whiteBtnWrapper, classes.btnWrapper)}
          >
            <>
              <img src={iconShieldDone} alt="acknowledgeIcon" />
              {translate('resources.data.bulkActions.acknowledgeSelected')}
            </>
          </ReusableButton>
          <AssignDialog
            open={assignDialogOpen}
            onSelect={handleAssignDialogSelectValue}
            onClose={() => setAssignDialogOpen(false)}
            users={users}
          />
          <AckDialog
            open={ackDialogOpen}
            onSelect={handleAddAcknowledgeSelectValue}
            onClose={() => setAckDialogOpen(false)}
          />
          <ReusableButton
            onClick={() => setAssignDialogOpen(true)}
            viewType="black"
            classNameWrapper={classes.btnWrapper}
            size="xl"
          >
            <>
              <img src={iconUser} alt="assignIcon" />
              {translate('resources.data.bulkActions.assignSelected')}
            </>
          </ReusableButton>
        </>
      )}

      <ReusableButton
        onClick={() => setDeleteDialogOpen(true)}
        viewType="red"
        classNameWrapper={classes.btnWrapper}
        size="xl"
      >
        <>
          <img src={deleteIcon} alt="deleteIcon" />
          {translate('resources.data.bulkActions.deleteSelected')}
        </>
      </ReusableButton>
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={onDeleteHandler}
        onCancel={() => setDeleteDialogOpen(false)}
        title={translate('common.deleteDialogHeader')}
        content={translate('common.deleteDialogText')}
      />
    </>
  );
};

BulkActionButtons.propTypes = {
  url: PropTypes.string,
  resource: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  showAssignment: PropTypes.bool,
  excludeFromIdList: PropTypes.number,
};

export default BulkActionButtons;
