const letterStatuses = Object.freeze({
  unrecognized: { name: 'Unrecognized', hex: 'rgb(107 112 116)' },
  attentionRequired: { name: 'Attention Required', hex: '#ff8f00' },
  processed: { name: 'Processed', hex: '#67AD5B', type: 'processed' },
  failed: { name: 'Failed', hex: '#d32f2f' },
  quotaExceeded: { name: 'Quota Exceeded', hex: '#283593' },
});

export const letterStatusesOptions = [
  { value: 'Unrecognized', label: 'Unrecognized' },
  { value: 'AttentionRequired', label: 'Attention Required' },
  { value: 'Processed', label: 'Processed' },
  { value: 'Failed', label: 'Failed' },
  { value: 'QuotaExceeded', label: 'Quota Exceeded' },
];

export default letterStatuses;
