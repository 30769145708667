import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import { deviceMonitorsEvents } from '@ui/pages/uptimeMonitors/DeviceMonitors/constants';

import useStyles from './styles';

const EventsWrapper = ({ deviceEventType }) => {
  const event = deviceMonitorsEvents[deviceEventType];
  const { icon: Icon } = event;
  const classes = useStyles({ deviceEventType, color: event.color });

  return (
    <Box width="100%" height="100%">
      <div className={classes.eventContainer}>
        {event.name}
        <Icon className={classes.icon} />
      </div>
    </Box>
  );
};

EventsWrapper.propTypes = {
  deviceEventType: PropTypes.number,
};

export default EventsWrapper;
