import { createAsyncThunk } from '@reduxjs/toolkit';
import { charterPhoneNumber } from '@services/requests/phoneNumbers';
import { BUFFER_PHONE_NUMBERS } from '@store/slices/resources';

export const ThunkCharterPhone = createAsyncThunk(
  `${BUFFER_PHONE_NUMBERS}/charterPhone`,
  async phoneInfo => charterPhoneNumber(phoneInfo),
);

export default {};
