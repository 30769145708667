import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { MessagingConfigurationAutocompleteSelector } from '@store/slices/messaging';
import { ThunkGetMessagingConfigurationsAutocomplete } from '@store/slices/messaging/thunks';

import { CREATE_MESSAGING_CHANNEL, MESSAGING_CHANNEL } from '@constants/routes';

import ViewIcon from '@assets/icons/visibility_blue.svg';
import TicketIcon from '@assets/icons/ticket_active.svg';

import ActionFieldItem from '@ui/components/common/ActionButtons/ActionFieldItem';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import { Grid } from '@material-ui/core';
import DropDown from '../../Auth/Common/DropDown';
import Loading from '../../common/Loading/Loading';
import TableBulkActions from './components/TableBulkActions';
import TableActions from './components/TableActions';
import TicketInfoDialog from './components/TicketInfoDialog';

import useStyles from './styles';

const MessagingChannels = () => {
  const [loading, setLoading] = useState(true);
  const [selectedConfiguration, setSelectedConfiguration] = useState(
    localStorage.getItem('selectedMessagingConfiguration') || undefined,
  );
  const [openTicketInfoDialog, setOpenTicketInfoDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(undefined);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const configurations = useSelector(
    MessagingConfigurationAutocompleteSelector,
  );

  const handleViewClick = useCallback(
    id => {
      window.open(
        `#${MESSAGING_CHANNEL.replace(':channelId', id).replace(
          ':configurationId',
          selectedConfiguration,
        )}`,
        '_blank',
      );
    },
    [selectedConfiguration],
  );

  const handleDropdownChange = useCallback(configurationID => {
    setSelectedConfiguration(configurationID);
    localStorage.setItem('selectedMessagingConfiguration', configurationID);
  }, []);

  const handleTicketInfoClick = useCallback(id => {
    setSelectedId(id);
    setOpenTicketInfoDialog(true);
  }, []);

  const handleAddChannelClick = () => {
    push(
      CREATE_MESSAGING_CHANNEL.replace(
        ':configurationId',
        selectedConfiguration,
      ),
    );
  };

  const columns = [
    {
      name: 'ID',
      key: 'id',
      sortable: true,
      width: 100,
    },
    {
      name: 'Channel Number',
      key: 'channelNumber',
      type: TableTypes.phoneNumber,
      sortable: true,
      searchable: true,
    },
    {
      name: 'Client Number',
      key: 'userNumber',
      type: TableTypes.phoneNumber,
      sortable: true,
      searchable: true,
    },
    {
      name: 'PSA Contact Name',
      key: 'userName',
      sortable: true,
      searchable: true,
    },
    {
      name: 'Ticket ID',
      key: 'ticketId',
      sortable: true,
      searchable: true,
      isCustomAccessor: true,
      accessor: item => item.ticketNumber || item.ticketId,
    },
    {
      name: 'Status',
      key: 'isClosed',
      width: 150,
      isCustomAccessor: true,
      accessor: item => (item.isClosed ? 'Closed' : 'Open'),
    },
    {
      name: 'Actions',
      key: TableTypes.actions,
      type: TableTypes.actions,
      width: 150,
      accessor: item => (
        <Grid container alignItems="center">
          <ActionFieldItem
            icon={ViewIcon}
            alt="view"
            handler={() => {
              handleViewClick(item.id);
            }}
            toolTip="View"
          />
          <ActionFieldItem
            icon={TicketIcon}
            alt="ticket"
            handler={() => {
              handleTicketInfoClick(item.id);
            }}
            toolTip="Ticket Info"
            style={{ marginTop: '6px' }}
          />
        </Grid>
      ),
    },
  ];

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetMessagingConfigurationsAutocomplete());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    if (
      !selectedConfiguration &&
      !loading &&
      configurations.length > 0 &&
      configurations.length === 1
    ) {
      handleDropdownChange(configurations[0].value);
    }
  }, [configurations, loading, selectedConfiguration, handleDropdownChange]);

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <div>
        <Table
          actionsComponent={
            <div className={classes.actions}>
              <DropDown
                options={configurations}
                classNameWrapper={classes.dropDown}
                input={{
                  onChange: e => {
                    handleDropdownChange(e.target.value);
                  },
                  value: selectedConfiguration,
                }}
              />
              {selectedConfiguration && (
                <TableActions handleAddChannelClick={handleAddChannelClick} />
              )}
            </div>
          }
          columns={columns}
          bulkActions={
            <TableBulkActions selectedConfiguration={selectedConfiguration} />
          }
          resource={`MessagingBoard/config/${selectedConfiguration}/channels`}
          defaultFilters={{ isClosed: 'false' }}
          defaultSort={{ fieldName: 'id', order: 'Desc' }}
          isDisplayTable={!!selectedConfiguration}
        />
      </div>

      {openTicketInfoDialog && (
        <TicketInfoDialog
          open={openTicketInfoDialog}
          setOpen={setOpenTicketInfoDialog}
          channelId={selectedId}
        />
      )}
    </div>
  );
};

MessagingChannels.propTypes = {};

export default MessagingChannels;
