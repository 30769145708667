import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Dialog, DialogContent, Typography } from '@material-ui/core';
import { Field, Form, useForm } from 'react-final-form';
import Input from '@components/Auth/Common/Input';
import {
  ThunkValidateCharterPhone,
  ThunkCharterPhone,
} from '@store/slices/bufferPhoneNumbers/thunks';
import ReusableButton from '@common/Button/Button';

import DropDown from '@components/Auth/Common/DropDown';
import useStyles from './styles';

const header = 'Import phone number from Twilio account';
const description =
  'Please input the phone number you want to import from Twilio';

const options = [
  { label: 'US (+1)', value: 0, code: '+1' },
  { label: 'AU (+61)', value: 1, code: '+61' },
  { label: 'GB (+44)', value: 2, code: '+44' },
  { label: 'CA (+1)', value: 3, code: '+1' },
  { label: 'NL (+31)', value: 4, code: '+31' },
];

const getData = values => {
  const selectedRegion = options.find(p => p.value === values.region);
  const phoneNumber = selectedRegion.code + values.phoneNumber;
  return { phoneNumber, region: selectedRegion.value };
};

const ImportModalForm = ({ open, onClose, onSubmit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    region: 0,
    phoneNumber: '',
  };

  const submit = values => {
    setLoading(true);
    dispatch(ThunkCharterPhone(getData(values)))
      .unwrap()
      .then(data => {
        enqueueSnackbar('The Phone Number is now available for assignment', {
          variant: 'success',
        });
        onSubmit(data);
        onClose();
      })
      .finally(() => setLoading(false));
  };

  const test = useCallback(
    form => {
      const formState = form.getState();
      if (formState.valid) {
        setLoading(true);
        dispatch(ThunkValidateCharterPhone(getData(formState.values)))
          .unwrap()
          .then(() =>
            enqueueSnackbar(
              'The Phone Number passes all tests, and can be imported',
              { variant: 'success' },
            ),
          )
          .finally(() => setLoading(false));
      }
    },
    [dispatch, enqueueSnackbar],
  );

  const validate = values => {
    const phoneNumberRegEx = /^\d{8,12}$/im;
    return {
      phoneNumber: !phoneNumberRegEx.test(values.phoneNumber)
        ? 'Invalid phone number format'
        : undefined,
    };
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogContent>
        <Typography className={classes.title}>{header}</Typography>
        <Typography variant="body2" color="textSecondary">
          {description}
        </Typography>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={initialValues}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div className={classes.componentsContainer}>
                <div className={classes.regionDropDown}>
                  <Field
                    id="region"
                    name="region"
                    fullWidth
                    options={options}
                    component={DropDown}
                  />
                </div>
                <Field
                  id="phoneNumber"
                  label="Phone number"
                  name="phoneNumber"
                  styleType="main"
                  inputView="text"
                  fullWidth
                  component={Input}
                  disabled={loading}
                  onChange={e =>
                    form.change(
                      'phoneNumber',
                      e.target.value.replace(/[^0-9]/g, ''),
                    )
                  }
                />
              </div>
              <div className={classes.buttons}>
                <ReusableButton
                  size="sm"
                  type="button"
                  disabled={loading}
                  loading={loading}
                  label="Cancel"
                  onClick={onClose}
                />
                <ReusableButton
                  size="sm"
                  type="button"
                  disabled={loading}
                  label="Test"
                  onClick={() => test(form)}
                />
                <ReusableButton
                  size="sm"
                  viewType="black"
                  type="submit"
                  disabled={loading}
                  loading={loading}
                  label="OK"
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

ImportModalForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ImportModalForm;
