import React, { useRef, useState, useEffect } from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import linkifyHtml from 'linkify-html';
import * as linkify from 'linkifyjs';

const EllipsisTooltip = ({ text, maxWidth, className }) => {
  const textRef = useRef(null);
  const [isOverflowed, setIsOverflowed] = useState(false);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (textRef.current) {
        const { clientWidth, scrollWidth } = textRef.current;
        setIsOverflowed(clientWidth < scrollWidth);
      }
    });

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => {
      if (textRef.current) {
        observer.unobserve(textRef.current);
      }
    };
  }, []);

  return (
    <Tooltip
      title={
        <Typography variant="body1">
          {typeof text === 'string' && linkify.find(text).length ? (
            <div
              dangerouslySetInnerHTML={{
                __html: linkifyHtml(text, { target: 'blank' }),
              }}
            />
          ) : (
            text
          )}
        </Typography>
      }
      disableHoverListener={!isOverflowed}
    >
      <Typography
        ref={textRef}
        noWrap
        className={className}
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          width: '100%',
          maxWidth,
          cursor: isOverflowed ? 'pointer' : undefined,
        }}
      >
        {typeof text === 'string' && linkify.find(text).length ? (
          <div
            dangerouslySetInnerHTML={{
              __html: linkifyHtml(text, { target: 'blank' }),
            }}
          />
        ) : (
          text
        )}
      </Typography>
    </Tooltip>
  );
};

EllipsisTooltip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  maxWidth: PropTypes.string,
  className: PropTypes.string,
};

export default EllipsisTooltip;
