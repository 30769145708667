import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import { Form } from 'react-final-form';
import ReusableButton from '@common/Button/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { actions } from '@store/actions';
import { useDispatch } from 'react-redux';
import InputWithAutocomplete from '@common/Input/InputWithAutocomplete';
import useStyles from '../styles';

const UsersFromCrmForm = ({ groupId, onClose, payload }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const submit = value => {
    setLoading(true);
    const data = {
      groupId,
      userIds: value.emails,
    };
    payload
      .onSubmit(data)
      .then(d => d.forEach(item => dispatch(actions.updateItemInList(item))))
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={!!payload}
    >
      <DialogTitle id="simple-dialog-title">
        Add notification users from&nbsp;
        {payload?.type}
      </DialogTitle>
      <DialogContent className={classes.editUserDialog}>
        <Form
          onSubmit={submit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div>
                <InputWithAutocomplete
                  name="emails"
                  options={payload?.options}
                  loading={loading}
                  disableCloseOnSelect
                  freeSolo={false}
                  getOptionValue={i => {
                    return typeof i === 'object'
                      ? i.value
                      : payload?.options.find(m => m.value === i)?.value;
                  }}
                  getOptionLabel={i =>
                    typeof i === 'object'
                      ? i.label
                      : payload?.options.find(m => m.value === i)?.label
                  }
                />
              </div>
              <div className={classes.buttonGroup}>
                <ReusableButton
                  label="resources.buttons.cancel"
                  onClick={onClose}
                  disabled={loading}
                />
                <ReusableButton
                  type="submit"
                  viewType="black"
                  label="resources.buttons.confirm"
                  loading={loading}
                  disabled={loading}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

UsersFromCrmForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  payload: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    onSubmit: PropTypes.func,
    type: PropTypes.string,
  }),
};

export default UsersFromCrmForm;
