import React, { useCallback, useState } from 'react';
import { useDataProvider, useRedirect, useTranslate } from 'react-admin';
import { useSnackbar } from 'notistack';
import { Prompt } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import Split from 'react-split';
import { PARSER } from '@constants/routes';
import ConfirmErrorsDialog from '@common/ConfirmDialog/ConfirmErrorsDialog';
import ReusableButton from '@common/Button/Button';
import DataViewCreator from '@components/Parsers/components/Create/components/DataViewCreator/DataViewCreator';
import { checkAnswer } from '@components/Parsers/components/Create/helpers';
import DisplayEmail from './components/DisplayEmail/DisplayEmail';
import ParserCreator from './components/ParserRootComponent/ParserRootComponent';
import useStyles from './styles';
import TestDialog from './components/TestDialog/TestDialog';
import { getTestData } from './helpers';

const CreateParser = ({ match }) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const { enqueueSnackbar } = useSnackbar();
  const translate = useTranslate();
  let currentLetter = null;
  const [errorsDialogOpen, setErrorsDialogOpen] = useState(false);
  const [changed, setChanged] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentParser, setCurrentParser] = useState(null);
  const [selectableMode, setSelectableSelMode] = useState(false);
  const [parsingErrors, setParsingErrors] = useState([]);
  const [modelForSave, setModelForSave] = useState([]);

  const [createDataViewDialogOpen, setCreateDataViewDialogOpen] = useState(
    false,
  );

  const handleBack = () =>
    redirect(`/mailbox/${match.params.mailBoxId}/documents`);

  const reprocess = (path, successMessage) => {
    dataProvider
      .create(path, {})
      .then(() => {
        enqueueSnackbar(successMessage, { variant: 'success' });
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const handleSubmit = (model, dataView) => {
    setChanged(false);
    const data = {
      name: model.name,
      parserData: JSON.stringify(model),
      fieldTransformer: model.fieldTransformer,
      dataView,
      ...model,
    };
    dataProvider
      .create(
        `${PARSER}?mailBoxId=${match.params.mailBoxId}&letterId=${match.params.letterId}`,
        { data },
      )
      .then(() =>
        reprocess(
          `${PARSER}/${match.params.letterId}/reprocessLetter`,
          'ReParsing for letter scheduled',
        ),
      )
      .then(() => {
        handleBack();
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const handleCheckDataView = () => {
    setCreateDataViewDialogOpen(true);
  };

  const parsingErrorsArray = [];

  const makeDecision = model => {
    setModelForSave(model);
    if (parsingErrorsArray.length > 0) {
      setParsingErrors(parsingErrorsArray);
      setErrorsDialogOpen(true);
    } else {
      parsingErrorsArray.splice(0, parsingErrorsArray.length);
      setParsingErrors([]);
      handleCheckDataView();
    }
  };

  const onErrorsSubmit = () => {
    setErrorsDialogOpen(false);
    handleCheckDataView();
  };

  const testBeforeSubmit = model => {
    getTestData({
      name: model.name,
      parserData: JSON.stringify(model),
      fieldTransformer: model.fieldTransformer,
      message: JSON.stringify(currentLetter),
    })
      .then(data => checkAnswer(data, model, parsingErrorsArray))
      .catch(e => console.log(e.message))
      .finally(() => makeDecision(model));
  };

  const handleTest = model => {
    setCurrentParser({
      name: model.name,
      parserData: JSON.stringify(model),
      fieldTransformer: model.fieldTransformer,
      message: JSON.stringify(currentLetter),
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentParser(null);
  };

  const handleCurrentLetterLoaded = value => {
    currentLetter = value;
  };

  const onDataChanged = useCallback(() => setChanged(true), []);
  return (
    <>
      <Prompt
        when={changed}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ReusableButton
              classNameWrapper={classes.buttonWrapper}
              size="md"
              onClick={handleBack}
            >
              <>
                <ArrowBackIcon className={classes.iconBack} />
                {translate('resources.buttons.back')}
              </>
            </ReusableButton>
          </Grid>
        </Grid>
        <Split
          split="vertical"
          sizes={[65, 35]}
          expandToMin={false}
          gutterSize={10}
          gutterAlign="center"
          snapOffset={30}
          dragInterval={1}
          direction="horizontal"
          cursor="col-resize"
          className={classes.splitContainer}
        >
          <DisplayEmail
            mailBoxId={match.params.mailBoxId}
            letterId={match.params.letterId}
            setCurrentLetter={handleCurrentLetterLoaded}
            selectableMode={selectableMode}
            setSelectableSelMode={setSelectableSelMode}
          />
          <ParserCreator
            onSubmit={testBeforeSubmit}
            onTest={handleTest}
            selectableMode={selectableMode}
            setSelectableSelMode={setSelectableSelMode}
            onChange={onDataChanged}
          />
        </Split>
        {open && (
          <TestDialog
            open={open}
            onClose={handleClose}
            parser={currentParser}
            messageId={match.params.letterId}
            mailBoxId={match.params.mailBoxId}
          />
        )}
        <ConfirmErrorsDialog
          open={errorsDialogOpen}
          setOpen={setErrorsDialogOpen}
          onAccept={onErrorsSubmit}
          onCancel={() => setErrorsDialogOpen(false)}
          title="There are some errors during parser checking. Do you want to continue?"
          errors={parsingErrors}
        />
        {createDataViewDialogOpen && (
          <DataViewCreator
            open={createDataViewDialogOpen}
            setOpen={setCreateDataViewDialogOpen}
            model={modelForSave}
            onSubmit={handleSubmit}
            mailBoxId={+match.params.mailBoxId}
          />
        )}
      </div>
    </>
  );
};

export default CreateParser;
