import { useEffect, useState } from 'react';
import { getDefaultsAutocomplete } from '@components/Ticketing/Notifications/helpers';
import { useSelector } from 'react-redux';
import { ticketSettings } from '@store/selectors/notifications';
import { useTranslate } from 'react-admin';
import { useSnackbar } from 'notistack';
import crmSources from '@constants/crmSources';

const useTicketingSettings = ({
  setData,
  isCreate,
  paramsId,
  getDetailsFunc,
}) => {
  const [allDefaults, setAllDefaults] = useState([]);
  const [defaultsOptions, setDefaultsOptions] = useState([]);
  const translate = useTranslate();

  const { enqueueSnackbar } = useSnackbar();
  const storedData = useSelector(ticketSettings);

  const crmOptions = Object.values(crmSources).map((i, idx) => ({
    label: i.label,
    value: idx,
  }));

  const getOpenDescription = value => {
    const direction = value ? 'close' : 'open';
    return translate(
      `resources.notificationSettings.ticketSettings.${direction}TicketText`,
    );
  };
  const handleCrmChanged = v => {
    setDefaultsOptions(allDefaults.filter(o => o.info === v));
  };

  useEffect(() => {
    getDefaultsAutocomplete()
      .then(allOptions => {
        setAllDefaults(allOptions);
        let crm;
        if (isCreate && storedData && Object.keys(storedData).length !== 0) {
          crm = allOptions.find(
            item => item.value === storedData.crmTicketDefaultId,
          )?.info;
          setDefaultsOptions(allOptions.filter(o => o.info === crm));
          setData(storedData);
        } else {
          getDetailsFunc(paramsId)
            .then(ticketSettingsData => {
              crm = allOptions.find(
                item => item.value === ticketSettingsData.crmTicketDefaultId,
              )?.info;
              const fullData = {
                crm,
                ...ticketSettingsData,
              };
              setDefaultsOptions(allOptions.filter(o => o.info === crm));
              setData(fullData);
            })
            .catch(() =>
              enqueueSnackbar('Unable to fetch data', { variant: 'error' }),
            );
        }
      })
      .catch(() =>
        enqueueSnackbar('Unable to fetch defaults options', {
          variant: 'error',
        }),
      );
  }, [
    paramsId,
    getDetailsFunc,
    isCreate,
    setData,
    storedData,
    enqueueSnackbar,
  ]);

  return {
    defaultsOptions,
    crmOptions,
    allDefaults,
    getOpenDescription,
    handleCrmChanged,
  };
};

export default useTicketingSettings;
