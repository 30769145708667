import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTeamsBotZip } from '@services/requests/microsoft';

export const ThunkDownloadTeamsBotZip = createAsyncThunk(
  'microsoft/downloadTeamsBotZip',
  async () => {
    const res = await getTeamsBotZip();

    const blob = await res.blob();

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'appPackage.zip';
    link.click();

    document.removeChild(link);

    return { success: true };
  },
);

export default {};
