import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import Input from '@common/Input/Input';
import Paper from '@common/Paper/Paper';
import ReusableButton from '@common/Button/Button';
import useStyles from '../formStyles';
import { createCrmIntegration } from '../../helpers';
import getValidation from '../CrmInfo/validators';
import getInitialValues from '../CrmInfo/initialValues';
import FormSelector from '../CrmInfo/FormSelector';

const BaseSettings = ({ crmType, onSubmit, onBack }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const submit = async values => {
    const data = {
      crmType,
      crmTicketDefaults: [],
      ...values,
    };
    setLoading(true);
    createCrmIntegration(data)
      .then(response => {
        setLoading(false);
        onSubmit(response.id);
      })
      .catch(e => {
        setLoading(false);
        enqueueSnackbar(`Unable to create integration: ${e.message}`, {
          variant: 'error',
        });
      });
  };

  return (
    <Paper>
      <Form
        onSubmit={submit}
        validate={values => getValidation(values, crmType)}
        initialValues={getInitialValues(null, crmType)}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div>
              <Field
                id="name"
                name="name"
                styleType="main"
                inputView="text"
                fullWidth
                component={Input}
                classNameWrapper={classes.inputWrapper}
                placeholder="Integration name"
                disabled={loading}
              />
              <FormSelector
                form={form}
                crmType={crmType}
                loading={loading}
                classes={classes}
              />
            </div>
            <div className={classes.buttonWrapper}>
              <ReusableButton
                size="md"
                type="button"
                label="resources.buttons.cancel"
                classNameWrapper={classes.button}
                onClick={onBack}
                disabled={loading}
              />
              <ReusableButton
                size="md"
                viewType="black"
                type="submit"
                classNameWrapper={classes.button}
                disabled={loading}
                loading={loading}
                label="resources.buttons.submit"
              />
            </div>
          </form>
        )}
      />
    </Paper>
  );
};

BaseSettings.propTypes = {
  crmType: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default BaseSettings;
