import { makeRequest } from '@services/requests/makeRequest';
import { TEAMS_BOT_CONFIGURATION } from '@services/requests/requestResources';

export const getTeamsBotConfiguration = () =>
  makeRequest('GET', `${TEAMS_BOT_CONFIGURATION}`);

export const getTeamsBotZip = () =>
  makeRequest('GET', `${TEAMS_BOT_CONFIGURATION}/zip`, undefined, false, false);

export const createOrUpdate = data =>
  makeRequest('POST', `${TEAMS_BOT_CONFIGURATION}`, data);

export default {};
