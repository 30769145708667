import React, { useMemo } from 'react';

import crmSources from '@constants/crmSources';

import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import PropTypes from 'prop-types';
import Actions from '@ui/pages/technicianVerification/AssignTicketToTechRequest/components/Actions';

const CommonTicketSelector = ({ psaDefaultId, psaId, itemId, type }) => {
  const actionColumn = useMemo(() => {
    return {
      name: 'Actions',
      key: TableTypes.actions,
      type: TableTypes.actions,
      width: 120,
      minWidth: 120,
      labelAlignment: 'center',
      accessor: item => <Actions item={item} psaId={psaId} itemId={itemId} />,
    };
  }, [itemId, psaId]);

  const content = useMemo(() => {
    switch (type) {
      case crmSources.ConnectWise.idx:
        return (
          <Table
            resource={`/TicketUpdate/connectWise/${psaDefaultId}/tickets`}
            columns={[
              {
                name: 'ID',
                key: 'id',
                sortable: true,
                searchable: true,
                width: 80,
              },
              { name: 'Summary', key: 'summary', searchable: true },
              { name: 'Board', key: 'board', sortable: true },
              { name: 'Status', key: 'status', sortable: true },
              { name: 'Contact', key: 'contact', searchable: true },
              actionColumn,
            ]}
          />
        );
      case crmSources.Autotask.idx:
        return (
          <Table
            resource={`/TicketUpdate/autotask/${psaDefaultId}/tickets`}
            columns={[
              {
                name: 'ID',
                key: 'ticketNumber',
                sortable: true,
                searchable: true,
              },
              { name: 'Title', key: 'title', searchable: true },
              { name: 'Queue', key: 'board', sortable: true },
              { name: 'Status', key: 'status', sortable: true },
              { name: 'Contact', key: 'contact', searchable: true },
              actionColumn,
            ]}
          />
        );
      case crmSources.Halo.idx:
        return (
          <Table
            resource={`/TicketUpdate/halo/${psaDefaultId}/tickets`}
            columns={[
              {
                name: 'Id',
                key: 'id',
                sortable: true,
                searchable: true,
              },
              { name: 'Summary', key: 'summary', searchable: true },
              { name: 'Team', key: 'team', sortable: true },
              { name: 'Status', key: 'statusName', sortable: true },
              { name: 'User', key: 'userName', searchable: true },
              actionColumn,
            ]}
          />
        );
      case crmSources.Kaseya.idx:
        return (
          <Table
            resource={`/TicketUpdate/kaseya/${psaDefaultId}/tickets`}
            columns={[
              {
                name: 'ID',
                key: 'ticketNumber',
                sortable: true,
                searchable: true,
              },
              { name: 'Title', key: 'title', searchable: true },
              { name: 'Queue', key: 'queue', sortable: true },
              { name: 'Status', key: 'status', sortable: true },
              { name: 'Contact', key: 'contact', searchable: true },
              actionColumn,
            ]}
          />
        );
      case crmSources.SuperOps.idx:
        return (
          <Table
            resource={`/TicketUpdate/superOps/${psaDefaultId}/tickets`}
            columns={[
              {
                name: 'Display ID',
                key: 'displayId',
                sortable: true,
                searchable: true,
              },
              { name: 'Subject', key: 'subject', searchable: true },
              { name: 'Client', key: 'client.name', sortable: true },
              { name: 'Status', key: 'status', sortable: true },
              { name: 'Requester', key: 'requester.name', searchable: true },
              actionColumn,
            ]}
          />
        );
      case crmSources.SyncroMsp.idx:
        return (
          <Table
            resource={`/TicketUpdate/syncro/${psaDefaultId}/tickets`}
            columns={[
              {
                name: 'Ticket Number',
                key: 'ticketNumber',
                sortable: true,
                searchable: true,
              },
              { name: 'Subject', key: 'subject', searchable: true },
              { name: 'Customer', key: 'customer', sortable: true },
              { name: 'Status', key: 'status', sortable: true },
              { name: 'Contact', key: 'contactName', searchable: true },
              actionColumn,
            ]}
          />
        );
      case crmSources.Zendesk.idx:
        return (
          <Table
            resource={`/TicketUpdate/zendesk/${psaDefaultId}/tickets`}
            columns={[
              {
                name: 'ID',
                key: 'id',
                sortable: true,
                searchable: true,
              },
              { name: 'Subject', key: 'subject', searchable: true },
              { name: 'Status', key: 'status', sortable: true },
              { name: 'Requester', key: 'requester', searchable: true },
              actionColumn,
            ]}
          />
        );
      default:
        return <></>;
    }
  }, [type, psaDefaultId, actionColumn]);

  return content;
};

CommonTicketSelector.propTypes = {
  psaDefaultId: PropTypes.number,
  psaId: PropTypes.number,
  itemId: PropTypes.number,
  type: PropTypes.string,
};

export default CommonTicketSelector;
