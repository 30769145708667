import React, { useState } from 'react';
import PropTypes from 'prop-types';

import crmSources from '@constants/crmSources';
import {
  TEXT_OPEN_POD_DIALOG,
  TEXT_OPEN_POD_SECURE_DATA,
} from '@constants/texts/pod';

import OptInIcon from '@assets/icons/documents.svg';
import ClientPortalIcon from '@assets/icons/monitor.svg';
import ShieldPersonIcon from '@assets/icons/shield_person.svg';
import SyncIcon from '@assets/icons/secureDataSend.svg';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import OptInDialog from '@components/integrations/connectwise/dialogs/OptInDialog';
import ClientPortalAssignDialog from '@components/PsaContacts/components/dialogs/autotask/ClientPortalAssignDialog';
import PodDialog from '@components/PodDialog';

import useStyles from '../../../styles';

const HaloContactTableActions = ({ record, psaId }) => {
  const [openOptInDialog, setOpenOptInDialog] = useState(false);
  const [openClientPortalDialog, setOpenClientPortalDialog] = useState(false);
  const [openSecureDialog, setOpenSecureDialog] = useState(false);
  const [openPodDialog, setOpenPodDialog] = useState(false);

  const classes = useStyles();

  return (
    <div className={classes.tableActions}>
      <ActionFieldItem
        alt={TEXT_OPEN_POD_DIALOG}
        handler={() => {
          setOpenPodDialog(true);
        }}
        toolTip={TEXT_OPEN_POD_DIALOG}
        icon={ShieldPersonIcon}
        ignoreDefaultIconSize
      />

      <ActionFieldItem
        alt="Opt-in form"
        handler={() => {
          setOpenOptInDialog(true);
        }}
        toolTip="Opt-in form"
        icon={OptInIcon}
      />
      <ActionFieldItem
        alt="Client portal"
        handler={() => {
          setOpenClientPortalDialog(true);
        }}
        toolTip="Client portal"
        icon={ClientPortalIcon}
      />
      <ActionFieldItem
        alt={TEXT_OPEN_POD_SECURE_DATA}
        handler={() => setOpenSecureDialog(true)}
        toolTip={TEXT_OPEN_POD_SECURE_DATA}
        icon={SyncIcon}
        ignoreDefaultIconSize
      />

      {openPodDialog && (
        <PodDialog
          itemId={record.id}
          onClose={() => {
            setOpenPodDialog(false);
          }}
          itemType="contact"
          psaId={psaId}
          psa={crmSources.Halo.label}
        />
      )}

      {/* OPT IN */}
      {openOptInDialog && (
        <OptInDialog
          open={openOptInDialog}
          setOpen={setOpenOptInDialog}
          data={{
            ContactPhone: record.phone,
            ContactEmail: record.email,
            ContactId: record.id,
            TicketId: 0,
          }}
          psaId={psaId}
          hideNote
          psaType={crmSources.Halo.label}
        />
      )}

      {/* Send secure data */}
      {openSecureDialog && (
        <PodDialog
          itemId={record.id}
          onClose={() => {
            setOpenSecureDialog(false);
          }}
          itemType="contact"
          psaId={psaId}
          psa={crmSources.Halo.label}
          page="secureData"
        />
      )}
      {/* Client portal */}
      {openClientPortalDialog && (
        <ClientPortalAssignDialog
          open={openClientPortalDialog}
          setOpen={setOpenClientPortalDialog}
          record={record}
          psaId={psaId}
          contactId={record.id}
          contactName={record.name}
        />
      )}
    </div>
  );
};

HaloContactTableActions.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  psaId: PropTypes.number,
};
export default HaloContactTableActions;
