import React, { useEffect, useState } from 'react';
import { useRedirect, useSafeSetState } from 'react-admin';
import { useSnackbar } from 'notistack';

import useAsync from '@services/api/common/useAsync';
import ReusableButton from '@common/Button/Button';
import useStyles from './styles';

import UnrecognizedTypeItem from './UnrecognizedTypeItem';
import {
  getUnrecognizedTypes,
  getUnrecognizedTypesById,
  submitData,
} from './helpers';

const UnrecognizedTypes = ({
  match,
  unrecognizedValues = [],
  onClose = () => {},
}) => {
  const { mailBoxId } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const redirect = useRedirect();
  const classes = useStyles();
  const [loading, setLoading] = useSafeSetState(false);
  const [models, setModels] = useState([]);

  const [typeValues, setTypeValues] = useState([]);
  useAsync(getUnrecognizedTypes, setTypeValues);

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (unrecognizedValues.length === 0) {
      getUnrecognizedTypesById(mailBoxId).then(res => setItems(res));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onItemChange = item => {
    const newModels = [...models];
    const idx = newModels.findIndex(i => i.id === item.id);
    if (idx === -1) {
      newModels.push(item);
    } else {
      newModels[idx] = item;
    }
    setModels(newModels);
  };

  const goBack = () => {
    redirect(`/mailbox/${mailBoxId}/documents`);
  };

  const onSubmit = async () => {
    setLoading(true);
    submitData(Number(mailBoxId) || 0, models)
      .then(() => {
        enqueueSnackbar('Successfully submitted', { variant: 'success' });
        setLoading(false);
        if (unrecognizedValues.length > 0) {
          onClose();
        } else {
          goBack();
        }
      })
      .catch(e => {
        setLoading(false);
        enqueueSnackbar(e.message, { variant: 'error' });
      });
  };

  return (
    <div className={`${classes.font} ${classes.center}`}>
      <h2>Resolve Unrecognized Values</h2>
      {unrecognizedValues.length === 0 &&
        items.map(i => (
          <UnrecognizedTypeItem
            key={i.id}
            item={i}
            onChange={onItemChange}
            options={typeValues.filter(v => v.typeId === i.typeId)}
          />
        ))}
      {unrecognizedValues.length > 0 &&
        unrecognizedValues.map(i => (
          <UnrecognizedTypeItem
            key={i.id}
            item={i}
            onChange={onItemChange}
            options={typeValues.filter(v => v.typeId === i.typeId)}
          />
        ))}

      <div>
        <div className={classes.modalButtonContainer}>
          <ReusableButton
            label="resources.buttons.cancel"
            onClick={
              unrecognizedValues && unrecognizedValues.length > 0
                ? onClose
                : goBack
            }
            disabled={loading}
            classNameWrapper={classes.modalCancelButtonContainer}
          />
          <ReusableButton
            viewType="black"
            onClick={onSubmit}
            disabled={loading}
            classNameWrapper={classes.button}
            loading={loading}
            label="resources.buttons.submit"
          />
        </div>
      </div>
    </div>
  );
};
export default UnrecognizedTypes;
