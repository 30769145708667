/* eslint-disable import/no-named-default */
import React, { useCallback, useState } from 'react';
import { useDataProvider, useRedirect, useTranslate } from 'react-admin';
import { useSnackbar } from 'notistack';
import { Prompt } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons/';
import Split from 'react-split';
import { PARSER } from '@constants/routes';
import Loading from '@common/Loading/Loading';
import useAsync from '@services/api/common/useAsync';
import { default as GET } from '@services/api/common/getData';
import localStorage from '@services/localStorage';
import appConfig from '@configs/appConfig';
import { parsers } from '@constants/appRoutes';
import ReusableButton from '@common/Button/Button';
import ConfirmErrorsDialog from '@common/ConfirmDialog/ConfirmErrorsDialog';
import afterSubmitActions from '@constants/afterSubmitActions';
import localStorageConst from '@constants/localStorage';
import './styles.css';
import { checkAnswer } from '@components/Parsers/components/Create/helpers';
import DataViewCreator from '@components/Parsers/components/Create/components/DataViewCreator/DataViewCreator';
import { getTestData } from './helpers';
import ParserCreator from './components/ParserRootComponent/ParserRootComponent';
import DisplayEmail from './components/DisplayEmail/DisplayEmail';
import TestDialog from './components/TestDialog/TestDialog';
import useStyles from './styles';

const UpdateParser = ({ match }) => {
  const afterSaveFromStorage =
    localStorage.getItem(localStorageConst.AFTERSAVE_ACTION) ??
    afterSubmitActions.None;
  const classes = useStyles();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const { enqueueSnackbar } = useSnackbar();
  const translate = useTranslate();

  const [errorsDialogOpen, setErrorsDialogOpen] = useState(false);
  const [changed, setChanged] = React.useState(false);
  const [selectableMode, setSelectableSelMode] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [currentParser, setCurrentParser] = useState(null);
  const [afterSaveAction, setAfterSaveAction] = useState(afterSaveFromStorage);
  const [createDataViewDialogOpen, setCreateDataViewDialogOpen] = useState(
    false,
  );
  let currentLetter = null;
  const [parser, setParser] = useState({});
  const getParser = useCallback(
    () => GET(appConfig.baseUrl.concat(parsers).concat(match.params.parserId)),
    [match.params.parserId],
  );
  useAsync(getParser, setParser);

  const storeAfterSaveAction = value => {
    localStorage.setItem(localStorageConst.AFTERSAVE_ACTION, value);
    setAfterSaveAction(value);
  };

  const handleBack = () =>
    redirect(`/mailbox/${match.params.mailBoxId}/documents`);

  const reprocess = (path, successMessage) => {
    dataProvider
      .create(path, {})
      .then(() => {
        enqueueSnackbar(successMessage, { variant: 'success' });
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const reParseMessage = () =>
    reprocess(
      `${PARSER}/${match.params.letterId}/reprocessLetter`,
      'ReParsing for letter scheduled',
    );
  const reParseMessages = () =>
    reprocess(
      `${PARSER}/${match.params.parserId}/reprocessLast50Letters`,
      'ReParsing last 50 letters for parser scheduled',
    );

  const update = (model, dataView) => {
    setChanged(false);
    const data = {
      name: model.name,
      parserData: JSON.stringify(model),
      fieldTransformer: model.fieldTransformer,
      mailBoxesIds: [match.params.mailBoxId],
      dataView,
      ...model,
    };
    dataProvider
      .update(PARSER, { id: match.params.parserId, data })
      .then(() => {
        enqueueSnackbar('Parser successfully updated', { variant: 'success' });
        switch (afterSaveAction) {
          case afterSubmitActions.reParseCurrent:
            reParseMessage();
            break;
          case afterSubmitActions.reParseLast50:
            reParseMessages();
            break;
          default:
            break;
        }
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const parsingErrorsArray = [];

  const [parsingErrors, setParsingErrors] = useState([]);
  const [modelForSave, setModelForSave] = useState([]);

  const handleCheckDataView = () => {
    setCreateDataViewDialogOpen(true);
  };

  const makeDecision = model => {
    setModelForSave(model);
    if (parsingErrorsArray.length > 0) {
      setParsingErrors(parsingErrorsArray);
      setErrorsDialogOpen(true);
    } else {
      parsingErrorsArray.splice(0, parsingErrorsArray.length);
      setParsingErrors([]);
      handleCheckDataView();
    }
  };

  const onErrorsSubmit = () => {
    setErrorsDialogOpen(false);
    handleCheckDataView();
  };

  const testBeforeSubmit = model => {
    getTestData({
      name: model.name,
      parserData: JSON.stringify(model),
      fieldTransformer: model.fieldTransformer,
      message: JSON.stringify(currentLetter),
    })
      .then(data => checkAnswer(data, model, parsingErrorsArray))
      .catch(e => console.log(e.message))
      .finally(() => makeDecision(model));
  };

  const handleTest = model => {
    setCurrentParser({
      name: model.name,
      parserData: JSON.stringify(model),
      fieldTransformer: model.fieldTransformer,
      message: JSON.stringify(currentLetter),
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentParser(null);
  };

  const loaded = !!parser.id;
  const handleCurrentLetterLoaded = value => {
    currentLetter = value;
  };

  const onDataChanged = useCallback(() => setChanged(true), []);

  return (
    <>
      <Prompt
        when={changed}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <div className={classes.root}>
        {loaded ? (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ReusableButton
                classNameWrapper={classes.buttonWrapper}
                size="md"
                onClick={handleBack}
              >
                <>
                  <ArrowBackIcon className={classes.iconBack} />
                  {translate('resources.buttons.back')}
                </>
              </ReusableButton>
            </Grid>
            <Split
              split="vertical"
              sizes={[65, 35]}
              expandToMin={false}
              gutterSize={10}
              gutterAlign="center"
              snapOffset={30}
              dragInterval={1}
              direction="horizontal"
              cursor="col-resize"
              className={classes.splitContainer}
            >
              <DisplayEmail
                mailBoxId={match.params.mailBoxId}
                letterId={match.params.letterId}
                setCurrentLetter={handleCurrentLetterLoaded}
                selectableMode={selectableMode}
                setSelectableSelMode={setSelectableSelMode}
              />
              <ParserCreator
                onSubmit={testBeforeSubmit}
                parser={parser}
                onTest={handleTest}
                selectableMode={selectableMode}
                setSelectableSelMode={setSelectableSelMode}
                onChange={onDataChanged}
                afterSubmitAction={afterSaveAction}
                setAfterSubmitAction={storeAfterSaveAction}
              />
            </Split>
          </Grid>
        ) : (
          <Loading />
        )}
        {open && (
          <TestDialog
            open={open}
            onClose={handleClose}
            parser={currentParser}
            messageId={match.params.letterId}
            mailBoxId={match.params.mailBoxId}
          />
        )}
        <ConfirmErrorsDialog
          open={errorsDialogOpen}
          setOpen={setErrorsDialogOpen}
          onAccept={onErrorsSubmit}
          onCancel={() => setErrorsDialogOpen(false)}
          title="There are some errors during parser checking. Do you want to continue?"
          errors={parsingErrors}
        />
        {createDataViewDialogOpen && (
          <DataViewCreator
            open={createDataViewDialogOpen}
            setOpen={setCreateDataViewDialogOpen}
            model={modelForSave}
            onSubmit={update}
            mailBoxId={+match.params.mailBoxId}
            parserId={+match.params.parserId}
          />
        )}
      </div>
    </>
  );
};

export default UpdateParser;
