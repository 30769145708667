import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import DialogTitle from '@common/Dialog/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Field, Form } from 'react-final-form';
import Input from '@common/Input/Input';
import ReusableButton from '@common/Button/Button';
import { requiredValidator } from '@utils/validators';
import { actions } from '@store/actions';
import { deleteTenant } from './helpers';

const useStyles = makeStyles(theme => ({
  '@global': {
    '@keyframes blinker': {
      '50%': {
        opacity: '0',
      },
    },
  },
  dialogContainer: {
    minWidth: '35rem',
    minHeight: '9rem',
  },
  font: {
    textAlign: 'center',
  },
  spaceAfter: {
    marginRight: '5px',
  },
  bold: {
    fontWeight: 'bold',
  },
  navButtonsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '12rem',
    },
    paddingTop: '0.5625rem',
    paddingBottom: '0.5625rem',
    marginRight: '1rem',
  },
  formContainer: {
    margin: '2rem',
  },
  blinking: {
    color: 'red',
    animation: 'blinker 1s linear infinite',
  },
}));

const empty = {
  id: '',
  tenantAdmin: '',
  tenantName: '',
  usersCount: '',
};

const getPostfix = users => `user${users === '1' ? '' : 's'}`;

const DeleteDialog = ({ isOpen, onClose, record }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const data = record || empty;
  const [loading, setLoading] = useState(false);

  const validate = values => {
    return {
      superAdminKey: requiredValidator(values.superAdminKey),
    };
  };

  const submit = values => {
    setLoading(true);
    deleteTenant(record.id, values.superAdminKey)
      .then(() => {
        dispatch(actions.removeItemFromList(record));
        onClose();
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setLoading(false));
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle onClose={onClose}>Deleting tenant</DialogTitle>
      <DialogContent>
        <div className={classes.font}>
          <div>
            <span className={cx(classes.spaceAfter, classes.blinking)}>
              Warning!
            </span>
            <span>This operation can not be undone.</span>
          </div>
          <div>
            <span className={classes.spaceAfter}>You plan to delete</span>
            <span className={cx(classes.spaceAfter, classes.bold)}>
              {data.tenantName}
            </span>
            <span className={classes.spaceAfter}>which has</span>
            <span className={classes.spaceAfter}>
              {`${data.usersCount} ${getPostfix(data.usersCount)}`}
            </span>
          </div>
        </div>
        <div className={cx(classes.dialogContainer, classes.font)}>
          <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate className={classes.form}>
                <div className={classes.formContainer}>
                  <Field
                    id="superAdminKey"
                    name="superAdminKey"
                    styleType="main"
                    inputView="text"
                    fullWidth
                    component={Input}
                    placeholder="Superuser secret key"
                    disabled={loading}
                  />
                </div>
                <div className={classes.navButtonsContainer}>
                  <ReusableButton
                    size="md"
                    type="button"
                    label="common.cancel"
                    classNameWrapper={classes.button}
                    onClick={onClose}
                    disabled={loading}
                    loading={loading}
                  />
                  <ReusableButton
                    size="md"
                    viewType="red"
                    type="submit"
                    label="common.submit"
                    classNameWrapper={classes.button}
                    disabled={loading}
                    loading={loading}
                  />
                </div>
              </form>
            )}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

DeleteDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.string,
    tenantAdmin: PropTypes.string,
    tenantName: PropTypes.string,
    usersCount: PropTypes.string,
  }),
};

export default DeleteDialog;
