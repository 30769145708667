import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import { useDispatch } from 'react-redux';

import FileUploader from '@common/FileUploader/FileUploader';
import ReusableButton from '@common/Button/Button';

import { INFO_IMAGE_TEXT } from '@constants/common';

import useStyles from './styles';

const FileUploaderComponent = ({
  name = 'logoPath',
  onUploadCallBack,
  resourceId,
  handleUpload,
  handleRemove,
  imageWidth = undefined,
  className,
}) => {
  const classes = useStyles();

  const [processing, setProcessing] = useState(false);

  const { getState, change } = useForm();
  const { values } = getState();

  const dispatch = useDispatch();

  const handleImageUpload = useCallback(
    file => {
      dispatch(handleUpload({ resourceId, file }))
        .unwrap()
        .then(res => {
          const url = URL.createObjectURL(file);
          change(name, url);
          if (onUploadCallBack) {
            onUploadCallBack(res);
          }
        });
    },
    [dispatch, resourceId],
  );

  const handleImageRemove = useCallback(async () => {
    setProcessing(true);
    await dispatch(handleRemove({ resourceId }))
      .unwrap()
      .then(() => {
        change(name, null);
      })
      .finally(() => {
        setProcessing(false);
      });
  }, [dispatch, resourceId, name]);

  const getContent = useMemo(() => {
    if (values[name]) {
      return (
        <div
          style={{ display: 'flex', justifyContent: 'space-between' }}
          className={className}
        >
          <img
            src={values[name]}
            alt="widget"
            style={{ width: imageWidth ?? '40px' }}
          />
          <ReusableButton
            label="Remove"
            onClick={handleImageRemove}
            loading={processing}
            disabled={processing}
          />
        </div>
      );
    }
    return (
      <FileUploader
        handleUpload={handleImageUpload}
        type="file"
        accept="image/png, image/jpeg"
        maxSizeBytes={2000000}
        maxFiles={1}
      />
    );
  }, [
    values,
    name,
    handleImageUpload,
    imageWidth,
    handleImageRemove,
    processing,
    className,
  ]);

  return (
    <>
      <div>{getContent}</div>
      <div className={classes.imageText}>{INFO_IMAGE_TEXT}</div>
    </>
  );
};

FileUploaderComponent.propTypes = {
  name: PropTypes.string,
  onUploadCallBack: PropTypes.func,
  resourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  handleUpload: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default FileUploaderComponent;
