import React from 'react';

import ConnectwiseContactsTableActions from '@components/PsaContacts/components/integrations/connectwise/ConnectwiseContactsTableActions';
import KaseyaContactTableActions from '@components/PsaContacts/components/integrations/kaseya/KaseyaContactTableActions';
import ZendeskContactTableActions from '@components/PsaContacts/components/integrations/zendesk/ZendeskContactTableActions';
import SuperOpsContactTableActions from '@components/PsaContacts/components/integrations/superOps/SuperOpsContactTableActions';
import AutotaskContactsTableActions from '@components/PsaContacts/components/integrations/autotask/AutotaskContactsTableActions';
import HaloContactTableActions from '@components/PsaContacts/components/integrations/halo/HaloContactTableActions';

import { TableTypes } from '@components/Table/constants';

import { optInColumn } from '@components/PsaContacts/helpers';
import { CREATE_TICKET } from '@services/requests/requestResources';

export const optInStatuses = [
  { value: 0, label: 'Opted-in', color: '#35C91F' },
  { value: 1, label: 'Opted-Out', color: '#C91118' },
  {
    value: 2,
    label: '--',
    title: 'This user has not yet filled in an Opt-in form',
  },
];

export const getKaseyaTableColumns = (psaId, companiesOptions) => [
  {
    searchable: true,
    name: 'First Name',
    key: 'firstName',
    minWidth: 150,
  },
  {
    searchable: true,
    name: 'Last Name',
    key: 'lastName',
    minWidth: 150,
  },
  {
    name: 'Account',
    key: 'companyName',
    sortable: true,
    searchable: true,
    type: TableTypes.dropdown,
    dropDownValues: companiesOptions,
    filterByAutocomplete: 'companyId',
    minWidth: 200,
  },
  {
    name: 'Phone',
    key: 'phone',
    searchable: true,
    type: TableTypes.phoneNumber,
    minWidth: 150,
  },
  {
    name: 'Email',
    key: 'email',
    sortable: true,
    searchable: true,
    minWidth: 150,
  },
  {
    name: 'Is Verified',
    key: 'isVerified',
    type: TableTypes.boolean,
    minWidth: 125,
    width: 125,
  },
  { ...optInColumn() },
  {
    name: 'Actions',
    key: 'actions',
    minWidth: 135,
    width: 135,
    type: TableTypes.actions,
    accessor: item => <KaseyaContactTableActions record={item} psaId={psaId} />,
  },
];

export const getConnectWiseTableColumns = (psaId, syncDataIntoDatabase) => [
  {
    name: 'Name',
    key: 'name',
    sortable: true,
    searchable: true,
    minWidth: 150,
  },
  {
    name: 'Company',
    key: 'companyName',
    sortable: true,
    searchable: true,
    type: TableTypes.dropdown,
    filterByAutocomplete: 'companyId',
    optionsResource: `/Integration/v2/connectwise/${psaId}/companies?_sort=label`,
    minWidth: 200,
  },
  {
    name: 'Phone',
    key: 'phone',
    searchable: true,
    type: TableTypes.phoneNumber,
    minWidth: 150,
  },
  {
    name: 'Email',
    key: 'email',
    searchable: true,
    sortable: true,
    minWidth: 150,
  },
  {
    name: 'Is Verified',
    key: 'isVerified',
    width: 125,
    minWidth: 125,
    type: TableTypes.boolean,
  },
  {
    ...optInColumn(syncDataIntoDatabase),
  },
  {
    name: 'Client Portal',
    key: 'hasClientPortal',
    width: 150,
    minWidth: 150,
    type: TableTypes.boolean,
  },
  {
    name: 'Actions',
    key: TableTypes.actions,
    type: TableTypes.actions,
    minWidth: 200,
    width: 200,
    accessor: item => (
      <ConnectwiseContactsTableActions record={item} psaId={psaId} />
    ),
  },
];

export const getAutotaskTableColumns = (psaId, syncDataIntoDatabase) => [
  {
    name: 'Name',
    key: 'name',
    sortable: true,
    searchable: true,
    minWidth: 150,
  },
  {
    name: 'Company',
    key: 'companyName',
    sortable: true,
    searchable: true,
    type: TableTypes.dropdown,
    filterByAutocomplete: 'companyId',
    optionsResource: `/Integration/v2/autotask/${psaId}/companies?_sort=label`,
    minWidth: 200,
  },
  {
    name: 'Phone',
    key: 'additionalPhone',
    searchable: true,
    type: TableTypes.phoneNumber,
    minWidth: 150,
  },
  {
    name: 'Mobile Phone',
    key: 'phone',
    searchable: true,
    type: TableTypes.phoneNumber,
    minWidth: 150,
  },
  {
    name: 'Email',
    key: 'email',
    sortable: true,
    searchable: true,
    minWidth: 150,
  },
  {
    name: 'Is Verified',
    key: 'isVerified',
    width: 125,
    minWidth: 125,
    type: TableTypes.boolean,
  },
  { ...optInColumn(syncDataIntoDatabase) },
  {
    name: 'Client Portal',
    key: 'hasClientPortal',
    width: 150,
    minWidth: 150,
    type: TableTypes.boolean,
  },
  {
    name: 'Actions',
    width: 200,
    minWidth: 200,
    key: TableTypes.actions,
    type: TableTypes.actions,
    accessor: item => (
      <AutotaskContactsTableActions record={item} psaId={psaId} />
    ),
  },
];

export const getZendeskTableColumns = (psaId, companiesOptions) => [
  {
    name: 'Name',
    key: 'name',
    sortable: true,
    searchable: true,
    minWidth: 150,
  },
  {
    name: 'Organization',
    key: 'companyName',
    sortable: true,
    searchable: true,
    type: TableTypes.dropdown,
    dropDownValues: companiesOptions,
    filterByAutocomplete: 'companyId',
    minWidth: 200,
  },
  {
    name: 'Phone',
    key: 'phone',
    searchable: true,
    isPhoneNumber: true,
    minWidth: 150,
  },
  {
    name: 'Email',
    key: 'email',
    sortable: true,
    searchable: true,
    minWidth: 150,
  },
  {
    name: 'Is Verified',
    key: 'isVerified',
    type: TableTypes.boolean,
    minWidth: 125,
    width: 125,
  },
  { ...optInColumn() },
  {
    name: 'Actions',
    minWidth: 200,
    width: 200,
    key: TableTypes.actions,
    type: TableTypes.actions,
    accessor: item => (
      <ZendeskContactTableActions record={item} psaId={psaId} />
    ),
  },
];

export const getSuperOpsTableColumns = (psaId, companiesOptions) => [
  {
    name: 'Name',
    key: 'name',
    sortable: true,
    searchable: true,
    minWidth: 150,
  },
  {
    name: 'Client',
    key: 'companyName',
    sortable: true,
    searchable: true,
    type: TableTypes.dropdown,
    dropDownValues: companiesOptions,
    filterByAutocomplete: 'companyId',
    minWidth: 200,
  },
  {
    name: 'Phone',
    key: 'phone',
    isPhoneNumber: true,
    searchable: true,
    minWidth: 150,
  },
  {
    name: 'Email',
    key: 'email',
    sortable: true,
    searchable: true,
    minWidth: 150,
  },
  {
    name: 'Is Verified',
    key: 'isVerified',
    type: TableTypes.boolean,
    minWidth: 125,
    width: 125,
  },
  { ...optInColumn() },
  {
    name: 'Client Portal',
    key: 'hasClientPortal',
    type: TableTypes.boolean,
    minWidth: 150,
    width: 150,
  },
  {
    name: 'Actions',
    key: TableTypes.actions,
    type: TableTypes.actions,
    minWidth: 165,
    width: 165,
    accessor: item => (
      <SuperOpsContactTableActions record={item} psaId={psaId} />
    ),
  },
];

export const getHaloTableColumns = (psaId, syncDataIntoDatabase) => [
  {
    name: 'Name',
    key: 'name',
    sortable: true,
    searchable: true,
    minWidth: 150,
  },
  {
    name: 'Customer',
    key: 'companyName',
    filterByAutocomplete: 'clientId',
    searchable: true,
    sortable: true,
    type: TableTypes.dropdown,
    optionsResource: `${CREATE_TICKET}/${psaId}/halo/clients?_sort=label`,
    minWidth: 200,
  },
  {
    name: 'Phone',
    key: 'phone',
    searchable: true,
    type: TableTypes.phoneNumber,
    minWidth: 150,
  },
  {
    name: 'Email',
    key: 'email',
    sortable: true,
    searchable: true,
    minWidth: 150,
  },
  {
    name: 'Is Verified',
    key: 'isVerified',
    type: TableTypes.boolean,
    labelAlignment: 'center',
    minWidth: 125,
    width: 125,
  },
  { ...optInColumn(syncDataIntoDatabase) },
  {
    name: 'Client Portal',
    key: 'hasClientPortal',
    type: TableTypes.boolean,
    labelAlignment: 'center',
    minWidth: 150,
    width: 150,
  },
  {
    name: 'Actions',
    key: TableTypes.actions,
    type: TableTypes.actions,
    minWidth: 165,
    width: 165,
    accessor: item => <HaloContactTableActions record={item} psaId={psaId} />,
  },
];

export default {};
