import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import { ReactComponent as HelpIcon } from '@assets/icons/updated/help.svg';

import useStyles from './styles';

const Helper = ({ link, tooltip }) => {
  const classes = useStyles();

  const openLink = () => {
    window.open(link, '_blank');
  };

  return (
    <Tooltip title={tooltip} className={classes.icon}>
      <HelpIcon onClick={openLink} />
    </Tooltip>
  );
};

Helper.propTypes = {
  link: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
};

export default Helper;
