import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  headerCell: {
    background: '#fafafa',
  },
  inputWrapper: {
    width: '11.5rem',
    height: '2.5rem',
  },
  tableWrapper: {
    width: '100%',
  },
  container: {
    margin: '0 2rem',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '20px',
  },
  baseColor: {
    color: '#B56A5A',
  },
  column: {
    flexBasis: '33.33%',
  },
  column2: {
    flexBasis: '60.33%',
  },
  accordion: {
    backgroundColor: '#FDF0ED',
    border: '1px solid #F7E1DC',
  },
  chipRoot: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  iconWrapper: {
    color: '#CACACA',
    width: '24px',
    height: '24px',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.4)',
    },
  },
  inputEditContainer: {
    margin: '0 5px',
    display: 'flex',
    alignItems: 'center',
  },
  inputEditContainer_button: {
    background: 'none',
    border: 'none',
  },
  tableContainer: {
    margin: '8px 16px 0',
    '& tbody': {
      maxHeight: '57vh',
    },
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '& div': {
      margin: 0,
    },
  },
}));

export default useStyles;
