import React, { useState } from 'react';
import { useRedirect } from 'react-admin';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import { actions } from '@store/actions';
import { INTEGRATIONS } from '@constants/routes';
import crmSources from '@constants/crmSources';
import SelectCrm from './SelectCrm';
import BaseSettings from './BaseSettings';
import TicketDefaults from './TicketDefaults';
import { createTicketDefault } from '../../helpers';
import useStyles from './styles';

const steps = [
  { label: 'Select PSA', exludedPSAs: [] },
  { label: 'Input base settings', exludedPSAs: [] },
  {
    label: 'Create ticketing defaults',
    exludedPSAs: [crmSources.Microsoft.name],
  },
];

const CreateCrm = ({
  showSmallCrmIcon = false,
  makeStepForward = () => {},
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const [activeStep, setActiveStep] = useState(0);
  const [crmType, setCrmType] = useState('');
  const [crmId, setCrmId] = useState(0);

  const classes = useStyles();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSelectCrmType = name => {
    setCrmType(name);
    handleNext();
  };

  const handleSkipDefaultsCreation = () => {
    if (!showSmallCrmIcon) {
      redirect(INTEGRATIONS);
    } else {
      makeStepForward();
    }
  };

  const handleBaseSettingsSubmit = crmIdValue => {
    dispatch(actions.setCrmType({ crmType, crmId: crmIdValue }));
    setCrmId(crmIdValue);
    if (crmType === crmSources.Microsoft.name) {
      handleSkipDefaultsCreation();
    }
    handleNext();
  };

  const handleSubmitTicketDefaults = value => {
    const model = {
      crmSourceId: crmId,
      default: true,
      ...value,
    };
    createTicketDefault(model)
      .then(() => {
        enqueueSnackbar('Created', { variant: 'success' });
        if (!showSmallCrmIcon) {
          redirect(INTEGRATIONS);
        } else {
          makeStepForward();
        }
      })
      .catch(e => {
        enqueueSnackbar(`Unable to create ticket default: ${e.message}`, {
          variant: 'error',
        });
      });
  };

  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <SelectCrm
            handleSelect={handleSelectCrmType}
            showSmallCrmIcon={showSmallCrmIcon}
          />
        );
      case 1:
        return (
          <BaseSettings
            crmType={crmType}
            onSubmit={handleBaseSettingsSubmit}
            onBack={handleBack}
          />
        );
      case 2:
        return (
          <TicketDefaults
            data={{}}
            onSubmit={handleSubmitTicketDefaults}
            onBack={handleSkipDefaultsCreation}
            crmId={crmId}
            customCancelText="Skip this step"
            crmType={crmType}
          />
        );
      default:
        return <div />;
    }
  };
  return (
    <div>
      <Stepper activeStep={activeStep} classes={{ root: classes.root }}>
        {steps
          .filter(i => !i.exludedPSAs.includes(crmType))
          .map(i => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={i.label} {...stepProps}>
                <StepLabel {...labelProps}>{i.label}</StepLabel>
              </Step>
            );
          })}
      </Stepper>
      <div>{getStepContent(activeStep)}</div>
    </div>
  );
};

export default CreateCrm;
