import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import GenericSourcesLogsDetailsItem from '@components/GenericSources/pages/GenericSourcesLogs/components/GenericSourcesLogsDetailsItem';
import ReusableButton from '@common/Button/Button';

import Loading from '@common/Loading/Loading';
import { useDispatch } from 'react-redux';
import { ThunkGetLogsDetails } from '@store/slices/logRecords/thunks';
import useStyles from '../styles';

const DIALOG_WIDTH = '1000px';

const GenericSourcesLogsDetails = ({ open, setOpen, recordId }) => {
  const [logDetails, setLogDetails] = useState([]);
  const [logHeader, setLogHeader] = useState('');
  const [loading, setLoading] = useState(true);

  const classes = useStyles();
  const dispatch = useDispatch();

  const separateLogsAndLogSummary = useCallback(items => {
    if (!items || !items.length) {
      return;
    }

    const lastElement = items[items.length - 1];

    if (
      lastElement.requestNumber === 0 &&
      !lastElement.body &&
      !lastElement.response
    ) {
      items.pop();
      setLogHeader(lastElement.message);
    }
    setLogDetails(items);
  }, []);

  const onMount = useCallback(() => {
    if (!recordId) return;
    setLoading(true);
    dispatch(ThunkGetLogsDetails(recordId))
      .unwrap()
      .then(res => {
        separateLogsAndLogSummary(res?.requestExecutionDetails ?? []);
        setLoading(false);
      });
  }, [dispatch, separateLogsAndLogSummary, recordId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <div className={classes.logDetailHeader}>
          <span>Logs</span>
          <ReusableButton
            label="Close"
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent style={{ width: DIALOG_WIDTH }}>
        {loading ? (
          <Loading />
        ) : (
          <div className={classes.logsList}>
            <span className={classes.logHeader}>{logHeader}</span>
            {logDetails.map(log => (
              <GenericSourcesLogsDetailsItem
                log={log}
                key={crypto.randomUUID()}
              />
            ))}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

GenericSourcesLogsDetails.propTypes = {
  recordId: PropTypes.arrayOf(PropTypes.string),
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default GenericSourcesLogsDetails;
