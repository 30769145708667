import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { ThunkGetCrmOptions } from '@store/slices/crmTicket/thunks';

import { PSA_TECH_VERIFICATION } from '@constants/routes';

import Loading from '@common/Loading/Loading';
import DropDown from '@components/Auth/Common/DropDown';
import FieldWrapper from '@common/form/FieldWrapper';
import ReusableButton from '@common/Button/Button';
import CommonTicketSelector from './components/CommonTicketSelector';

const AssignTicketToTechVerification = () => {
  const [loading, setLoading] = useState(true);
  const [psaIntegrations, setPsaIntegrations] = useState([]);
  const [psaId, setPsaId] = useState('');

  const dispatch = useDispatch();
  const { push } = useHistory();
  const params = useParams();

  const content = useMemo(() => {
    if (psaId) {
      const selectedIntegration = psaIntegrations.find(i => i.crmId === psaId);

      return (
        <CommonTicketSelector
          psaId={psaId}
          psaDefaultId={selectedIntegration.defaultId}
          type={selectedIntegration.type}
          itemId={params.requestId}
        />
      );
    }
    return <></>;
  }, [params.requestId, psaId, psaIntegrations]);

  const onMount = useCallback(() => {
    setLoading(true);
    dispatch(ThunkGetCrmOptions())
      .unwrap()
      .then(res => {
        setPsaIntegrations(res);

        if (res.length === 1) {
          setPsaId(res[0].crmId);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: '100px 1fr',
        height: 'calc(100vh - 105px)',
        padding: '40px',
        boxSizing: 'border-box',
        overflow: 'hidden',
        gap: '10px',
      }}
    >
      <FieldWrapper
        label="PSA Integration"
        labelSize={12}
        contentSize={12}
        content={
          <DropDown
            input={{
              value: psaId,
              onChange: e => {
                setPsaId(e.target.value);
              },
            }}
            options={psaIntegrations}
            labelName="crmName"
            valueName="crmId"
          />
        }
      />

      {content}

      <div>
        <ReusableButton
          label="Back"
          onClick={() => {
            push(PSA_TECH_VERIFICATION);
          }}
        />
      </div>
    </div>
  );
};

export default AssignTicketToTechVerification;
