import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tableWrapper: {
    margin: '4rem 16px 0 11px',
    '& tbody': {
      maxHeight: '70vh',
    },
  },
  contentWrapper: {
    minHeight: '100px',
    minWidth: '200px',
    maxWidth: '80vw',
    padding: '8px',
  },
  detailsWrapper: {
    borderBottom: '1px solid grey',
    paddingBottom: '10px',
    marginBottom: '10px',
  },
}));

export default useStyles;
