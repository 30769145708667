import React from 'react';
import PropTypes from 'prop-types';
import {
  useAuthProvider,
  useRedirect,
  useSafeSetState,
  useTranslate,
} from 'react-admin';
import { useSnackbar } from 'notistack';
import { Field, Form } from 'react-final-form';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core';

import Input from '@common/Input/Input';
import ReusableButton from '@common/Button/Button';
import { confirmPasswordValidator, passwordValidator } from '@utils/validators';
import { lightTheme } from '@services/themes/mainTheme';
import useStyles from './loginStyles';

const theme = createTheme(lightTheme());

const ResetPasswordForm = ({ token }) => {
  const redirect = useRedirect();
  const auth = useAuthProvider();
  const translate = useTranslate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [loading, setLoading] = useSafeSetState(false);

  const validate = values => {
    const errors = { password: undefined, passwordConfirmation: undefined };
    const pwdErrors = passwordValidator(values.password);
    errors.password = pwdErrors || undefined;
    const pwdConfErrors = confirmPasswordValidator(
      values.passwordConfirmation,
      values,
    );
    errors.passwordConfirmation = pwdConfErrors || undefined;
    return errors;
  };

  const submit = values => {
    setLoading(true);
    const payload = {
      queryParam: token,
      password: values.password,
    };
    auth
      .resetPassword(payload)
      .then(() => redirect('/Login'))
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setLoading(false));
  };

  return (
    <ThemeProvider theme={theme}>
      <Form
        onSubmit={submit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate className={classes.form}>
            <div className={classes.fieldsContainer}>
              <Field
                id="password"
                name="password"
                styleType="main"
                inputView="text"
                fullWidth
                component={Input}
                placeholder={translate('ra.auth.password')}
                type="password"
                disabled={loading}
                autoComplete="current-password"
                classNameWrapper={classes.inputWrapper}
              />
              <Field
                id="passwordConfirmation"
                name="passwordConfirmation"
                styleType="main"
                inputView="text"
                fullWidth
                component={Input}
                placeholder={translate('ra.registration.confPwd')}
                type="password"
                disabled={loading}
                autoComplete="current-password"
                classNameWrapper={classes.inputWrapper}
              />
            </div>

            <ReusableButton
              size="xl"
              viewType="black"
              type="submit"
              classNameWrapper={classes.button}
              disabled={loading}
              loading={loading}
              label="resources.buttons.confirm"
            />
          </form>
        )}
      />
    </ThemeProvider>
  );
};

ResetPasswordForm.propTypes = {
  token: PropTypes.string.isRequired,
};

export default ResetPasswordForm;
