import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

import {
  ThunkCloseClientPortalChat,
  ThunkGetClientPortalChat,
  ThunkGetClientPortalChatMessages,
  ThunkReopenClientPortalChat,
  ThunkSendMessageToClientPortal,
} from '@store/slices/clientPortalAdmin/thunks';
import { SelectClientPortalMessages } from '@store/slices/clientPortalAdmin/selectors';
import { currentUserDataSelector } from '@store/selectors';

import Loading from '@common/Loading/Loading';
import UploadChatAttachmentDialog from '@components/LiveChat/dialogs/UploadChatAttachmentDialog';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PushMessagesToTicketNoteDialog from '@components/ClientPortalChat/dialogs/PushMessagesToTicketNoteDialog';
import PushMessagesToEmailDialog from '@components/ClientPortalChat/dialogs/PushMessagesToEmailDialog';
import ChatActions from '@components/ClientPortalChat/components/ChatActions';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import PropTypes from 'prop-types';
import Message from './components/Messages';

import useStyles from './styles';

const maxMessageLength = 1024;

// chatId props used to pass chatId value directly from connectwise pod page and don't bother client portal chat page flow.
const ClientPortalChat = ({ chatId = undefined, fromPodPage = false }) => {
  const [loading, setLoading] = useState(true);
  const [inputText, setInputText] = useState('');
  const [uploadOpen, setUploadOpen] = useState(false);
  const { id: clientPortalId } = useParams();
  const [id, setId] = useState(chatId ?? clientPortalId);
  const [pushToNoteDialog, setPushToNoteDialog] = useState(false);
  const [pushToEmailDialog, setPushToEmailDialog] = useState(false);
  const [chatData, setChatData] = useState(undefined);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [chatClosingInProgress, setChatClosingInProgress] = useState(false);
  const [chatReopenDialogOpen, setChatReopenDialogOpen] = useState(false);
  const [chatReopenInProgress, setChatReopenInProgress] = useState(false);

  const dispatch = useDispatch();
  const messages = useSelector(SelectClientPortalMessages);
  const classes = useStyles({ fromPodPage });
  const ref = useRef();
  const currentUser = useSelector(currentUserDataSelector);

  const isInputInvalid = useMemo(
    () => inputText && inputText.length > maxMessageLength,
    [inputText],
  );

  const handleMessageSend = useCallback(() => {
    if (isInputInvalid) {
      return;
    }

    setInputText('');
    dispatch(
      ThunkSendMessageToClientPortal({
        chatId: id,
        payload: {
          message: inputText,
          chatId: id,
          resourceName: currentUser.username,
        },
      }),
    );
  }, [dispatch, id, inputText]);

  const handleCloseChat = useCallback(() => {
    setChatClosingInProgress(true);
    dispatch(ThunkCloseClientPortalChat({ chatId: id }))
      .unwrap()
      .then(() => {
        setChatData({ ...chatData, isClosed: true });
      })
      .finally(() => {
        setConfirmDialogOpen(false);
        setChatClosingInProgress(false);
      });
  }, [dispatch, id]);

  const handleReopenChat = useCallback(() => {
    setChatReopenInProgress(true);
    dispatch(ThunkReopenClientPortalChat({ chatId: id }))
      .unwrap()
      .then(() => {
        setChatData({ ...chatData, isClosed: false });
      })
      .finally(() => {
        setChatReopenInProgress(false);
        setChatReopenDialogOpen(false);
      });
  }, []);

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetClientPortalChatMessages({ chatId: id }));
    await dispatch(ThunkGetClientPortalChat({ chatId: id }))
      .unwrap()
      .then(d => {
        setChatData(d);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, id]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    if (chatId) {
      setId(chatId);
    }
  }, [chatId]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [messages, ref]);

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      {fromPodPage ? (
        <ChatActions
          handlePushToTicket={() => setPushToNoteDialog(true)}
          handlePushToEmail={() => setPushToEmailDialog(true)}
          handleCloseChat={() => setConfirmDialogOpen(true)}
          handleReopenChat={() => {
            setChatReopenDialogOpen(true);
          }}
          isClosed={chatData.isClosed}
        />
      ) : (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            alignItems: 'center',
          }}
        >
          <ChatActions
            handlePushToTicket={() => setPushToNoteDialog(true)}
            handlePushToEmail={() => setPushToEmailDialog(true)}
            handleCloseChat={() => setConfirmDialogOpen(true)}
            handleReopenChat={() => {
              setChatReopenDialogOpen(true);
            }}
            isClosed={chatData.isClosed}
          />

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span
              onClick={() => {
                if (chatData) {
                  window.open(chatData.ticketLink, '_blank');
                }
              }}
              role="presentation"
              style={{ cursor: 'pointer' }}
            >
              Ticket: {chatData.ticketId}
            </span>
          </div>
        </div>
      )}

      <div className={classes.list}>
        {messages.map(message => (
          <Message
            message={message}
            key={message.id}
            fromPodPage={fromPodPage}
          />
        ))}
        <div ref={ref} className="ref" />
      </div>
      <div style={{ alignContent: 'end' }}>
        {isInputInvalid && (
          <div className={classes.validationError}>
            {`Please keep the input within ${maxMessageLength} characters`}
          </div>
        )}
        <div className={classes.chatInput}>
          <AttachFileIcon
            cursor="pointer"
            onClick={() => {
              if (!chatData.isClosed) {
                setUploadOpen(true);
              }
            }}
          />

          <TextField
            variant="outlined"
            className={classes.chatTextInput}
            multiline
            rows={1}
            rowsMax={3}
            value={inputText}
            onChange={({ target, currentTarget }) => {
              if (currentTarget.value !== '\n') {
                setInputText(target.value);
              }
            }}
            onKeyPress={() => {
              if (window.event.keyCode === 13) {
                handleMessageSend();
              }
            }}
            fullWidth
            disabled={chatData.isClosed}
          />

          <SendIcon
            style={{
              cursor: isInputInvalid ? 'not-allowed' : 'pointer',
              filter: isInputInvalid ? 'opacity(0.5)' : 'none',
            }}
            onClick={() => {
              if (!chatData.isClosed) {
                handleMessageSend();
              }
            }}
          />
        </div>
      </div>

      {uploadOpen && id && (
        <UploadChatAttachmentDialog
          chatId={id}
          setOpen={() => setUploadOpen(false)}
          open={uploadOpen}
          toClientPortal
        />
      )}

      {pushToNoteDialog && (
        <PushMessagesToTicketNoteDialog
          setOpen={setPushToNoteDialog}
          clientChatId={chatId || chatData.id}
          open={pushToNoteDialog}
        />
      )}

      {pushToEmailDialog && (
        <PushMessagesToEmailDialog
          setOpen={setPushToEmailDialog}
          open={pushToEmailDialog}
          clientPortalChatId={chatId || chatData.id}
        />
      )}

      <ConfirmDialog
        open={!!confirmDialogOpen}
        setOpen={setConfirmDialogOpen}
        onAccept={handleCloseChat}
        onCancel={() => setConfirmDialogOpen(false)}
        title="Close chat"
        content="Warning! This chat will be closed and the chat session will be logged to the ticket if enabled."
        loading={chatClosingInProgress}
      />

      {chatReopenDialogOpen && (
        <ConfirmDialog
          open={chatReopenDialogOpen}
          setOpen={setChatReopenDialogOpen}
          onAccept={handleReopenChat}
          onCancel={() => setChatReopenDialogOpen(false)}
          title="Re-open chat"
          content="Are you sure you want to re-open the selected chat?"
          loading={chatReopenInProgress}
        />
      )}
    </div>
  );
};

ClientPortalChat.propTypes = {
  chatId: PropTypes.number,
  fromPodPage: PropTypes.bool,
};

export default ClientPortalChat;
