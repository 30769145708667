import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Check, Close } from '@material-ui/icons';
import { Checkbox, TableCell } from '@material-ui/core';
import classNames from 'classnames';
import { Skeleton } from '@material-ui/lab';

import nationalFormatPhoneNumber from '@utils/nationalFormatPhoneNumber';
import EllipsisTooltip from '@common/EllipsisTooltip';
import { currentUserDataSelector } from '@store/selectors';

import { TableContext } from '../../context';
import { TableTypes } from '../../constants';

import useStyles from '../../styles';

const TableCellContent = ({
  type,
  data,
  accessor = () => <></>,
  id,
  rowData,
  maxWidth,
  minWidth,
  isCustomAccessor,
  loading,
  isUpperCase,
}) => {
  const {
    selectedIds,
    addItemToSelectedIds,
    removeItemFromSelected,
  } = useContext(TableContext);

  const tenantCountryCode = useSelector(currentUserDataSelector)
    ?.tenantCountryCode;

  const classes = useStyles();

  const content = useMemo(() => {
    if (loading) {
      return <Skeleton className={classes.loader} />;
    }
    if (isCustomAccessor) {
      return accessor(rowData);
    }
    switch (type) {
      case TableTypes.phoneNumber:
        return <div>{nationalFormatPhoneNumber(data, tenantCountryCode)}</div>;
      case TableTypes.boolean:
        return (
          <div className={classes.booleanContainer}>
            {data ? <Check /> : <Close />}
          </div>
        );
      case TableTypes.actions:
        return accessor(rowData);
      case TableTypes.bulk:
        return (
          <Checkbox
            className={classes.checkbox}
            checked={selectedIds.includes(id)}
            onChange={e => {
              if (e.target.checked) {
                addItemToSelectedIds(id);
              } else {
                removeItemFromSelected(id);
              }
            }}
          />
        );
      default:
        return (
          <EllipsisTooltip
            className={classNames({ [classes.upperCase]: isUpperCase })}
            text={data}
          />
        );
    }
  }, [
    loading,
    isCustomAccessor,
    type,
    classes.loader,
    classes.booleanContainer,
    classes.checkbox,
    classes.upperCase,
    accessor,
    rowData,
    data,
    tenantCountryCode,
    selectedIds,
    id,
    isUpperCase,
    addItemToSelectedIds,
    removeItemFromSelected,
  ]);

  return (
    <TableCell
      className={classes.tableCell}
      style={{
        maxWidth: maxWidth ? `${maxWidth}px` : `100%`,
        minWidth,
      }}
    >
      {content}
    </TableCell>
  );
};

TableCellContent.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.string,
  accessor: PropTypes.func,
  isUpperCase: PropTypes.bool,
  loading: PropTypes.bool,
  id: PropTypes.string,
  isCustomAccessor: PropTypes.bool,
};

export default TableCellContent;
