import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { PSA_CONTACTS } from '@services/requests/requestResources';

import { getConnectWiseTableColumns } from '@components/PsaContacts/constants';
import Table from '@components/Table';
import PsaContactsTableBulkActions from '@components/PsaContacts/components/PsaContactsTableBulkActions';
import Loading from '@common/Loading/Loading';

import crmSources from '@constants/crmSources';

import { useCrmSyncSettings } from '../../../../../../hooks/useCrmSyncSettings';

const ConnectwiseContacts = ({ psaId }) => {
  const resource = useMemo(() => `${PSA_CONTACTS}/connectwise/${psaId}`, [
    psaId,
  ]);

  const {
    loading,
    crmSettings: { syncDataIntoDatabase },
  } = useCrmSyncSettings(psaId);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Table
          resource={resource}
          columns={getConnectWiseTableColumns(psaId, syncDataIntoDatabase)}
          bulkActions={
            <PsaContactsTableBulkActions
              psaId={psaId}
              psaName={crmSources.ConnectWise.name}
            />
          }
        />
      )}
    </>
  );
};

ConnectwiseContacts.propTypes = {
  psaId: PropTypes.number.isRequired,
};

export default ConnectwiseContacts;
