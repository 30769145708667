import React from 'react';
import { useSnackbar } from 'notistack';
import ReusableButton from '@common/Button/Button';
import cx from 'classnames';
import { getUrl } from '@components/WebHooks/helpers';
import CopyIcon from '@assets/icons/copy_blue.svg';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import copyText from '@utils/copy';
import PropTypes from 'prop-types';
import Templates from '@components/WebHooks/components/Templates';
import useStyles from '../styles';

const HookUrl = ({
  url,
  setUrl,
  setAwaitingHook,
  displayTemplates = false,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const onGetClick = () => {
    getUrl()
      .then(d => setUrl(d.url))
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  };

  const onCopy = key => {
    copyText(key);
  };

  return (
    <>
      <div className={cx(classes.displayFlex, classes.marginBottom)}>
        <ReusableButton
          size="md"
          viewType="black"
          label="common.get"
          onClick={onGetClick}
          disabled={url !== ''}
        />
        <div className={cx(classes.displayFlex, classes.urlInput)}>
          <span className={classes.urlText}>{url}</span>
        </div>
        <ActionFieldItem
          handler={() => onCopy(url)}
          toolTip="copy to clipboard"
        >
          <img src={CopyIcon} alt="copy to clipboard" />
        </ActionFieldItem>
      </div>
      {url && displayTemplates && (
        <Templates url={url} setAwaitingHook={setAwaitingHook} />
      )}
    </>
  );
};

HookUrl.propTypes = {
  url: PropTypes.string,
  setUrl: PropTypes.func,
  setAwaitingHook: PropTypes.func,
  displayTemplates: PropTypes.bool,
};

export default HookUrl;
