import React, { useState } from 'react';

import Table from '@components/Table';

import DateAccessor from '@common/MuiVirtualizedTable/components/accessors/DateAccessor';
import { TableTypes } from '@components/Table/constants';
import ViewInfoModal from '@components/Admin/NotificationLogs/ViewInfoModal';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';

import eyeIcon from '@assets/icons/visibility_blue.svg';
import viewActionsIcon from '@assets/icons/document_blue.svg';

import useStyles from './styles';

const NotificationLogs = () => {
  const [rowData, setRowData] = useState();

  const classes = useStyles();

  return (
    <div className={classes.tableWrapper}>
      <Table
        columns={[
          {
            name: 'Date time',
            key: 'dateTime',
            minWidth: 175,
            isCustomAccessor: true,
            accessor: row => <DateAccessor value={row.dateTime} />,
          },
          {
            name: 'Type',
            key: 'type',
            minWidth: 100,
            type: TableTypes.dropdown,
            filterByAutocomplete: 'type',
            optionsResource: '/notificationsLogs/notification-types',
            sortable: true,
            searchable: true,
          },
          {
            name: 'Execution time',
            key: 'executionTime',
            minWidth: 175,
            isCustomAccessor: true,
            accessor: row => <>{`${row.executionTime} (seconds)`}</>,
          },
          {
            name: 'Actions',
            key: 'actions',
            type: 'actions',
            width: 100,
            minWidth: 100,
            accessor: ({ actions, details }) => (
              <>
                <ActionFieldItem
                  handler={() => setRowData({ actions })}
                  toolTip={
                    actions.length
                      ? 'View actions'
                      : "There aren't any Notification Groups associated with this Notification"
                  }
                  disabled={!actions.length}
                >
                  <img src={viewActionsIcon} alt="logIcon" />
                </ActionFieldItem>
                <ActionFieldItem
                  handler={() => setRowData({ details })}
                  toolTip={details.length ? 'View details' : 'No any details'}
                  disabled={!details.length}
                >
                  <img src={eyeIcon} alt="details" />
                </ActionFieldItem>
              </>
            ),
          },
        ]}
        resource="/notificationsLogs/notification-checker-logs"
        defaultSort={{ fieldName: 'id', order: 'DESC' }}
      />
      {rowData && <ViewInfoModal setRowData={setRowData} rowData={rowData} />}
    </div>
  );
};

export default NotificationLogs;
