import React from 'react';
import PropTypes from 'prop-types';

import Table from '@components/Table';
import PsaContactsTableBulkActions from '@components/PsaContacts/components/PsaContactsTableBulkActions';
import Loading from '@common/Loading/Loading';

import { getHaloTableColumns } from '@components/PsaContacts/constants';
import { useCrmSyncSettings } from '../../../../../../hooks/useCrmSyncSettings';

const HaloContacts = ({ psaId, resource, psaName }) => {
  const {
    loading,
    crmSettings: { syncDataIntoDatabase },
  } = useCrmSyncSettings(psaId);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Table
          resource={resource}
          columns={getHaloTableColumns(psaId, syncDataIntoDatabase)}
          defaultSort={{ fieldName: 'name', order: 'DESC' }}
          bulkActions={
            <PsaContactsTableBulkActions psaId={psaId} psaName={psaName} />
          }
        />
      )}
    </>
  );
};

HaloContacts.propTypes = {
  psaId: PropTypes.number.isRequired,
  resource: PropTypes.string,
  psaName: PropTypes.string,
};

export default HaloContacts;
