import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import ReusableButton from '@ui/components/common/Button/Button';
import Loading from '@ui/components/common/Loading/Loading';
import UnrecognizedTypeItem from '@ui/components/UnrecognizedTypes/UnrecognizedTypeItem';

import {
  getUnrecognizedTypes,
  submitData,
} from '@ui/components/UnrecognizedTypes/helpers';
import { getUnrecognizedValues } from '../../helpers';

import useStyles from './styles';

const WebHookResultUnrecognized = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [allTypes, setAllTypes] = useState([]);
  const [unrecognizedValues, setUnrecognizedValues] = useState([]);
  const [models, setModels] = useState([]);

  const { id } = match.params;
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const { goBack } = useHistory();

  const onItemChange = item => {
    const newModels = [...models];
    const idx = newModels.findIndex(i => i.id === item.id);
    if (idx === -1) {
      newModels.push(item);
    } else {
      newModels[idx] = item;
    }
    setModels(newModels);
  };

  const onSubmit = async () => {
    setLoading(true);
    // mailbox id is dropped to 0 because it is not needed for the webhook but required for the API call
    submitData(0, models)
      .then(() => {
        enqueueSnackbar('Successfully submitted', { variant: 'success' });
        setLoading(false);
        goBack();
      })
      .catch(e => {
        setLoading(false);
        enqueueSnackbar(e.message, { variant: 'error' });
      });
  };

  const onMount = () => {
    setLoading(true);
    getUnrecognizedTypes()
      .then(res => {
        setAllTypes(res);
      })
      .catch(err => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });

    getUnrecognizedValues(id)
      .then(res => {
        setUnrecognizedValues(res);
      })
      .catch(err => {
        enqueueSnackbar(err.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    onMount();
  }, [match]);

  if (loading) return <Loading />;
  return (
    <div>
      {unrecognizedValues.map((i, idx) => (
        <UnrecognizedTypeItem
          key={idx}
          item={{
            ...i,
            typeId: i.item1,
            value: i.item2,
            id: idx,
          }}
          onChange={onItemChange}
          options={allTypes.filter(v => v.typeId === i.item1)}
        />
      ))}

      <div>
        <div className={classes.buttonContainer}>
          <ReusableButton
            label="resources.buttons.cancel"
            onClick={goBack}
            disabled={loading}
          />
          <ReusableButton
            viewType="black"
            onClick={onSubmit}
            disabled={loading}
            loading={loading}
            label="resources.buttons.submit"
          />
        </div>
      </div>
    </div>
  );
};

export default WebHookResultUnrecognized;
