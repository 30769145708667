import React, { useState } from 'react';
import { useRedirect } from 'react-admin';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import Input from '@common/Input/Input';
import Paper from '@common/Paper/Paper';
import ReusableButton from '@common/Button/Button';
import { DATA_SOURCES_INTEGRATION } from '@constants/routes';
import { Typography } from '@material-ui/core';
import { createIntegration, updateIntegration } from '../../../helpers';
import getValidation from '../IntegrationInfo/validators';
import getInitialValues from '../IntegrationInfo/initialValues';
import FormSelector from '../IntegrationInfo/FormSelector';

import useStyles from '../formStyles';

const BaseSettings = ({ item, integrationType, onSubmit, onBack }) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const { enqueueSnackbar } = useSnackbar();
  const isEdit = Boolean(item);

  const [loading, setLoading] = useState(false);

  const submit = async values => {
    const data = {
      id: item?.id,
      type: integrationType,
      ...values,
    };
    setLoading(true);
    (isEdit ? updateIntegration(item?.id, data) : createIntegration(data))
      .then(() => {
        setLoading(false);
        onSubmit && onSubmit();
        redirect(DATA_SOURCES_INTEGRATION);
      })
      .catch(e => {
        setLoading(false);
        enqueueSnackbar(`Unable to create integration: ${e.message}`, {
          variant: 'error',
        });
      });
  };

  return (
    <Paper>
      <Form
        onSubmit={submit}
        validate={values => getValidation(values, integrationType)}
        initialValues={item && getInitialValues(item, integrationType)}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div>
              {isEdit && (
                <Typography className={classes.inputLabel}>
                  Integration Name:
                </Typography>
              )}
              <Field
                id="name"
                name="name"
                styleType="main"
                inputView="text"
                fullWidth
                component={Input}
                classNameWrapper={classes.inputWrapper}
                placeholder="Integration name"
                disabled={loading}
              />
              <FormSelector
                form={form}
                type={integrationType}
                loading={loading}
                classes={classes}
                useLabels={isEdit}
              />
            </div>
            <div className={classes.buttonWrapper}>
              <ReusableButton
                size="md"
                type="button"
                label="resources.buttons.cancel"
                classNameWrapper={classes.button}
                onClick={onBack}
                disabled={loading}
              />
              <ReusableButton
                size="md"
                viewType="black"
                type="submit"
                classNameWrapper={classes.button}
                disabled={loading}
                loading={loading}
                label="resources.buttons.submit"
              />
            </div>
          </form>
        )}
      />
    </Paper>
  );
};

BaseSettings.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  integrationType: PropTypes.number.isRequired,
  onSubmit: PropTypes.func,
  onBack: PropTypes.func.isRequired,
};

export default BaseSettings;
