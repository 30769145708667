import { makeRequest } from '../makeRequest';
import {
  CRM_DEFAULTS_TICKETS,
  CRM_SOURCE,
  INTEGRATION,
} from '../requestResources';

export const getCrmSources = (sort = 'name', order = 'ASC') =>
  makeRequest('GET', `${CRM_SOURCE}?_sort=${sort}&_order=${order}`);

export const getCrmDefaults = crmId =>
  makeRequest('GET', `${CRM_DEFAULTS_TICKETS}/${crmId}/all`);

export const getCrmDefaultBoards = crmId =>
  makeRequest('GET', `${INTEGRATION}/${crmId}/service-boards`);

export const getCrmBoardStatuses = (crmId, boardId) =>
  makeRequest('GET', `${INTEGRATION}/${crmId}/board-statuses/${boardId}`);

export const getCrmDefaultCompanies = crmId =>
  makeRequest('GET', `${INTEGRATION}/companies/${crmId}`);

export default {};
