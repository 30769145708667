import React from 'react';

import getFormattedDate from '@utils/getFormattedDate';

import BooleanAccessor from '@common/MuiVirtualizedTable/components/accessors/BooleanAccessor';
import nationalFormatPhoneNumber from '@utils/nationalFormatPhoneNumber';
import { TableTypes } from '@components/Table/constants';
import crmSources from '@constants/crmSources';

const booleanFieldOptions = [
  { label: 'All', value: '' },
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
];

export const verificationHistoryColumns = (options, tenantCountryCode) => [
  {
    name: 'Ticket Id',
    key: 'ticketId',
    sortable: true,
    searchable: true,
    isCustomAccessor: true,
    accessor: row => (
      <>
        {row.crmType === crmSources.Autotask.idx
          ? row.ticketNumber
          : row.ticketId}
      </>
    ),
    minWidth: 175,
  },
  {
    name: 'PSA',
    key: 'crmName',
    sortable: true,
    searchable: true,
    minWidth: 150,
  },
  {
    name: 'Company',
    key: 'contactCompany',
    sortable: true,
    searchable: true,
    minWidth: 150,
  },
  {
    name: 'Contact',
    key: 'contactName',
    sortable: true,
    searchable: true,
    minWidth: 150,
  },
  {
    name: 'Expired',
    key: 'confirmationExpired',
    isCustomAccessor: true,
    sortable: true,
    searchable: true,
    type: TableTypes.dropdown,
    dropDownValues: booleanFieldOptions,
    filterByAutocomplete: 'confirmationExpired',
    minWidth: 130,
    labelAlignment: 'center',
    accessor: rowData => (
      <BooleanAccessor value={rowData.confirmationExpired} useMultiColor />
    ),
  },
  {
    name: 'Confirmed',
    key: 'isConfirmed',
    isCustomAccessor: true,
    sortable: true,
    searchable: true,
    type: TableTypes.dropdown,
    dropDownValues: booleanFieldOptions,
    filterByAutocomplete: 'isConfirmed',
    minWidth: 130,
    labelAlignment: 'center',
    accessor: rowData => (
      <BooleanAccessor value={rowData.isConfirmed} useMultiColor />
    ),
  },
  {
    name: 'Technician',
    key: 'inspectorName',
    sortable: true,
    searchable: true,
    minWidth: 150,
  },
  {
    name: 'Type of Verification',
    key: 'verificationType',
    searchable: true,
    minWidth: 230,
    type: TableTypes.dropdown,
    dropDownValues: options || [],
    filterByAutocomplete: 'verificationType',
  },
  {
    name: 'Phone Number / Email',
    isCustomAccessor: true,
    searchable: true,
    key: 'value',
    accessor: rowData => (
      <>
        {rowData.email ||
          nationalFormatPhoneNumber(rowData.phoneNumber, tenantCountryCode)}
      </>
    ),
    minWidth: 250,
  },
  {
    name: 'Verification Date',
    key: 'verificationDateTime',
    isCustomAccessor: true,
    sortable: true,
    accessor: rowData => <>{getFormattedDate(rowData.verificationDateTime)}</>,
    minWidth: 200,
  },
];

export default {};
