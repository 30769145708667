import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@common/Paper/Paper';
import { Field, Form as MyForm } from 'react-final-form';
import { useSnackbar } from 'notistack';
import Input from '@common/Input/Input';
import FieldWrapper from '@common/form/FieldWrapper';
import TestArea from '@common/TestArea/TestArea';
import DropDown from '@components/Auth/Common/DropDown';
import deviceMonitorTypes, {
  types,
  ports,
  getMonitorTypeDescription,
} from '@constants/deviceMonitorTypes';
import ReusableButton from '@common/Button/Button';
import iconArrow from '@assets/icons/arrowGrey.svg';
import { useDataProvider, useRedirect, useTranslate } from 'react-admin';
import { DEVICE_MONITORS } from '@constants/routes';
import { basePermissions } from '@constants/permissions';
import useDisabled from '@components/Hooks/useDisabled';
import useTest from '@components/Hooks/useTest';
import SliderWrapper from '@components/DeviceMonitors/components/SliderWrapper';
import { requiredValidator } from '@utils/validators';
import { MenuItem, ListSubheader } from '@material-ui/core';
import InfoBox from '@common/InfoBox/InfoBox';
import FormControlCheckBox from '@common/Checkbox/FormControlCheckBox';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import {
  getCompaniesAutocompleteOption,
  getRegionsAutocompleteOptions,
} from '@components/DeviceMonitors/components/helpers';
import useDebounce from '@utils/useDebounce';
import resources from '@constants/resources';
import { deviceNameValidator } from '../../validators';
import useStyles from '../styles';
import { formattedData, loadData, custom } from './helpers';

import { testDeviceMonitor } from '../../helpers';

const debounceDelay = 300;

const handleErrors = values => {
  const isTypePort = values.type === deviceMonitorTypes.TcpPing.id;
  return {
    deviceName: deviceNameValidator(values.deviceName),
    type: requiredValidator(values.type),
    url: requiredValidator(values.url),
    ...(isTypePort && {
      port: requiredValidator(values.port),
    }),
    ...(values.port === custom &&
      isTypePort && {
        customPort: requiredValidator(values.customPort),
      }),
    period: requiredValidator(values.period),
  };
};

const Form = ({ data }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const redirect = useRedirect();
  const { enqueueSnackbar } = useSnackbar();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const debouncedValue = useDebounce(searchValue, debounceDelay, true);

  useEffect(() => {
    async function fetchDataForAutoComplete() {
      setLoading(true);
      const [companies, regions] = await Promise.all([
        getCompaniesAutocompleteOption(debouncedValue),
        getRegionsAutocompleteOptions(),
      ]);
      setCompaniesOptions([...companies]);
      setRegionOptions([...regions]);
      setLoading(false);
    }
    fetchDataForAutoComplete();
  }, [debouncedValue]);

  const initData = useMemo(() => loadData(data), [data]);
  const { handleTestClick, testOutput } = useTest({
    setLoading,
    apiRequest: testDeviceMonitor,
  });

  const { editMode, disabled } = useDisabled(
    loading,
    'deviceMonitors',
    basePermissions.update,
  );
  const handleBack = () => redirect(DEVICE_MONITORS);

  const create = values =>
    dataProvider.create(resources.deviceMonitors, { data: values });

  const update = values =>
    dataProvider.update(resources.deviceMonitors, {
      id: values.id,
      data: values,
    });

  const submit = async values => {
    const updatedData = formattedData(values);
    const action = updatedData.id ? update : create;
    setLoading(true);
    action(updatedData)
      .then(() => {
        enqueueSnackbar('Successfully done', { variant: 'success' });
        setLoading(false);
        handleBack();
      })
      .catch(e => {
        enqueueSnackbar(e.message, { variant: 'error' });
        setLoading(false);
      });
  };

  return (
    <Paper>
      <MyForm
        onSubmit={submit}
        validate={handleErrors}
        initialValues={initData}
        render={({ handleSubmit, form, values }) => {
          return (
            <form onSubmit={handleSubmit} noValidate className={classes.form}>
              <FieldWrapper
                label="Company"
                labelSize={3}
                content={
                  <Field
                    id="company"
                    name="company"
                    placeholder="Company name"
                    styleType="main"
                    inputView="text"
                    fullWidth
                    component={AutocompleteFormInput}
                    items={companiesOptions}
                    freeSolo
                    onInputChange={async (event, newInputValue) => {
                      setSearchValue(newInputValue);
                    }}
                    disabled={disabled}
                  />
                }
              />
              <FieldWrapper
                label="Site"
                labelSize={3}
                content={
                  <Field
                    id="site"
                    name="site"
                    placeholder="Site"
                    styleType="main"
                    inputView="text"
                    fullWidth
                    classNameWrapper={classes.inputWrapper}
                    component={Input}
                    disabled={disabled}
                  />
                }
              />
              <FieldWrapper
                label="Device Name"
                labelSize={3}
                content={
                  <Field
                    id="deviceName"
                    name="deviceName"
                    placeholder="Device name"
                    styleType="main"
                    inputView="text"
                    fullWidth
                    classNameWrapper={classes.inputWrapper}
                    component={Input}
                    disabled={disabled}
                  />
                }
              />
              <FieldWrapper
                label="Period [min]"
                labelSize={3}
                alignItems="center"
                content={
                  <Field
                    name="period"
                    id="period"
                    aria-label="period"
                    valueLabelDisplay="auto"
                    step={5}
                    input={{
                      value: values.period,
                      onChange: (_, value) => form.change('period', value),
                    }}
                    marks
                    min={5}
                    max={60}
                    className={classes.sliderWrapper}
                    component={SliderWrapper}
                    disabled={disabled}
                  />
                }
              />
              <FieldWrapper
                label="Type"
                labelSize={3}
                content={
                  <div className={classes.runningStateContainer}>
                    <Field
                      id="type"
                      name="type"
                      placeholder="Type"
                      options={types}
                      component={DropDown}
                      disabled={disabled}
                    />
                    <InfoBox text={getMonitorTypeDescription(values.type)} />
                  </div>
                }
              />

              <FieldWrapper
                label="Server region"
                labelSize={3}
                content={
                  <div className={classes.runningStateContainer}>
                    <Field
                      id="grpcServer"
                      name="grpcServer"
                      placeholder="Server region"
                      options={regionOptions}
                      component={DropDown}
                      disabled={disabled}
                    />
                  </div>
                }
              />

              <FieldWrapper
                label="Url"
                labelSize={3}
                content={
                  <Field
                    id="url"
                    name="url"
                    placeholder="Url"
                    styleType="main"
                    inputView="text"
                    fullWidth
                    classNameWrapper={classes.inputWrapper}
                    component={Input}
                    disabled={disabled}
                  />
                }
              />
              {values.type === deviceMonitorTypes.TcpPing.id && (
                <FieldWrapper
                  label="Port"
                  labelSize={3}
                  content={
                    <Field
                      id="port"
                      name="port"
                      placeholder="Port"
                      styleType="main"
                      inputView="text"
                      fullWidth
                      classNameWrapper={classes.inputWrapper}
                      options={ports}
                      component={props => (
                        <DropDown {...props}>
                          <ListSubheader disableSticky>
                            Popular Ports
                          </ListSubheader>
                          {ports.map(i => (
                            <MenuItem key={i.value} value={i.value}>
                              {i.label || 'All'}
                            </MenuItem>
                          ))}
                          <ListSubheader disableSticky>
                            Custom port
                          </ListSubheader>
                          <MenuItem key={custom} value={custom}>
                            Custom Port
                          </MenuItem>
                        </DropDown>
                      )}
                      disabled={disabled}
                    />
                  }
                />
              )}

              {values.port === custom &&
                values.type === deviceMonitorTypes.TcpPing.id && (
                  <FieldWrapper
                    label="Custom port"
                    labelSize={3}
                    content={
                      <Field
                        id="customPort"
                        name="customPort"
                        placeholder="Custom Port"
                        styleType="main"
                        inputView="text"
                        fullWidth
                        component={Input}
                        disabled={disabled}
                      />
                    }
                  />
                )}
              <FieldWrapper
                labelSize={3}
                content={
                  <Field
                    name="useSecondVm"
                    label="Request from another region if current failed"
                    type="checkbox"
                    className={classes.inputWrapper}
                    component={FormControlCheckBox}
                  />
                }
              />
              <TestArea
                onClick={() => {
                  handleTestClick({
                    url: values.url,
                    type: values.type,
                  });
                }}
                loading={loading}
                testOutput={testOutput}
                classNameWrapper={classes.testArea}
              />
              <div className={classes.buttonWrapper}>
                <ReusableButton
                  size="md"
                  type="button"
                  classNameWrapper={classes.button}
                  disabled={loading}
                  onClick={handleBack}
                >
                  <>
                    <img src={iconArrow} alt="iconArrow" />
                    {translate('resources.buttons.back')}
                  </>
                </ReusableButton>
                {editMode && (
                  <ReusableButton
                    size="md"
                    viewType="black"
                    type="submit"
                    classNameWrapper={classes.button}
                    disabled={loading}
                    loading={loading}
                  >
                    {translate(
                      data
                        ? 'resources.billing.updateButton'
                        : 'pages.role.permissions.create',
                    )}
                  </ReusableButton>
                )}
              </div>
            </form>
          );
        }}
      />
    </Paper>
  );
};

Form.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};

export default Form;
