import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';

import exportIcon from '@assets/icons/export.svg';
import { globalExportFile } from '@services/api';

import useStyles from './styles';

const ExportButton = ({
  disabled = false,
  globalExportType,
  globalExportFileType = 'csv',
  filters = {},
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    setLoading(true);
    const file = await globalExportFile(
      globalExportType,
      globalExportFileType,
      filters,
    );
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${globalExportType}.${globalExportFileType}`,
    );
    document.body.appendChild(link);
    link.click();
    // eslint-disable-next-line no-unused-expressions
    link?.parentNode?.removeChild(link);
    setLoading(false);
  };

  return (
    <Button
      onClick={onClick}
      disabled={loading || disabled}
      startIcon={
        loading ? (
          <CircularProgress className={classes.loader} />
        ) : (
          <img src={exportIcon} alt="export" />
        )
      }
      variant="contained"
      className={classes.exportButton}
    >
      Export
    </Button>
  );
};

ExportButton.propTypes = {
  disabled: PropTypes.bool,
  globalExportType: PropTypes.string,
  globalExportFileType: PropTypes.string,
  filters: PropTypes.object,
};

export default ExportButton;
