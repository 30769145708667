import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  Checkbox,
  InputLabel,
  TableCell,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

import { TypeLabelValue } from '@common/propTypes/common';
import { TableContext } from '../../context';
import { OrderTypes, TableTypes } from '../../constants';

import TableDropDown from '../../TableDropDown/TableDropDown';

import useStyles from '../../styles';

const TableHeadCell = ({ item, headRef }) => {
  const [isResizing, setIsResizing] = useState(false);
  const [search, setSearch] = useState('');

  const {
    setFilteringObject,
    setSortingObject,
    sortingField,
    sortingOrder,
    filtering,
    selectAllItems,
    deselectAllItems,
    selectAll,
    allIds,
    columnsWithoutWidth,
    changeColumnWidth,
  } = useContext(TableContext);

  const classes = useStyles();

  const handleStateChange = order => {
    setSortingObject(item.sortName || item.key, order);
  };

  const sortArrow = useMemo(
    () => (
      <>
        {item.type === TableTypes.bulk && (
          <Checkbox
            className={classes.checkbox}
            checked={selectAll}
            onChange={() => {
              if (!selectAll === true) {
                selectAllItems(!selectAll, allIds);
              } else {
                deselectAllItems(!selectAll);
              }
            }}
          />
        )}

        {item.sortable && sortingField !== (item.sortName || item.key) && (
          <div
            className={classes.arrow}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <ArrowDownward
              onClick={() => {
                handleStateChange(OrderTypes.ASC);
              }}
              style={{ opacity: '0.2' }}
            />
          </div>
        )}

        {item.sortable && sortingField === (item.sortName || item.key) && (
          <div className={classes.arrow}>
            {sortingOrder !== OrderTypes.ASC ? (
              <ArrowUpward
                onClick={() => {
                  handleStateChange(OrderTypes.ASC);
                }}
              />
            ) : (
              <ArrowDownward
                onClick={() => {
                  handleStateChange(OrderTypes.DESC);
                }}
              />
            )}
          </div>
        )}
      </>
    ),
    [
      item.type,
      item.sortable,
      item.key,
      classes.checkbox,
      classes.arrow,
      selectAll,
      sortingField,
      sortingOrder,
      selectAllItems,
      allIds,
      deselectAllItems,
      handleStateChange,
    ],
  );

  const label = useMemo(
    () => (
      <>
        {item.type === TableTypes.dropdown && (
          <TableDropDown
            options={item.dropDownValues}
            name={item.key}
            placeholder={item.name}
            filterByAutocomplete={item.filterByAutocomplete}
            optionsResource={item.optionsResource}
            isMultiple={item.isMultiple}
            limitTags={item.limitTags}
            filterCallBack={item.filterCallBack}
          />
        )}
        {item.searchable && item.type !== TableTypes.dropdown && (
          <TextField
            value={search}
            onChange={e => {
              setSearch(e.target.value);

              setFilteringObject(filtering, item.key, e.target.value);
            }}
            placeholder={item.name}
            label={item.name}
            fullWidth
            autoComplete="nope"
          />
        )}
        {!item.searchable && (
          <Tooltip title={item.tooltipTitle || ''}>
            <InputLabel
              id={item.key}
              style={{
                padding: '6px 0 7px',
              }}
            >
              {item.name}
            </InputLabel>
          </Tooltip>
        )}
      </>
    ),
    [
      item.type,
      item.dropDownValues?.length,
      item.key,
      item.searchable,
      item.name,
      search,
      setFilteringObject,
      filtering,
    ],
  );

  const handleMouseUp = useCallback(() => {
    if (isResizing) {
      setIsResizing(false);
      changeColumnWidth(
        item.key,
        +headRef.current.style.maxWidth.replace('px', ''),
      );
    }
  }, [isResizing, changeColumnWidth, item.key, headRef]);

  const handleMouseMove = useCallback(
    e => {
      if (isResizing) {
        e.preventDefault();

        const newWidth = `${e.clientX -
          headRef.current.getBoundingClientRect().left}px`;

        // eslint-disable-next-line no-param-reassign
        headRef.current.style.maxWidth = newWidth;
      }
    },
    [isResizing, headRef],
  );

  useEffect(() => {
    document.onmouseup = handleMouseUp;
    document.onmousemove = handleMouseMove;

    return () => {
      document.onmouseup = null;
      document.onmousemove = null;
    };
  }, [handleMouseUp, handleMouseMove]);

  const getJustificationForCell = useMemo(() => {
    if (item.type === TableTypes.bulk) {
      return 'center';
    }
    return item.labelAlignment ? item.labelAlignment : 'space-between';
  }, [item.labelAlignment, item.type]);

  const getAligmentForCell = useMemo(() => {
    if (item.type === TableTypes.bulk) {
      return 'center';
    }
    return 'flex-end';
  }, [item.type]);

  return (
    <TableCell
      ref={headRef}
      className={cx(classes.tableCell, classes.tableRowAligned)}
      style={{
        maxWidth: !item.width
          ? `calc(100% / ${columnsWithoutWidth})`
          : `${item.width}px`,
        justifyContent: getJustificationForCell,
        alignItems: getAligmentForCell,
        maxHeight: '65px',
        width: '100%',
        position: 'relative',
        minWidth: item.minWidth,
      }}
    >
      {label}
      {sortArrow}

      {item.type !== TableTypes.bulk && item.type !== TableTypes.actions && (
        <div
          onMouseDownCapture={() => {
            setIsResizing(true);
          }}
          id="resize"
          role="presentation"
          style={{
            cursor: 'col-resize',
            minWidth: '2px',
            height: 'calc(100% - 32px)',
            display: 'block',
            position: 'absolute',
            right: '3px',
            background: isResizing ? '#012169' : '#bbbbbb',
          }}
        />
      )}
    </TableCell>
  );
};

TableHeadCell.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.string,
    sortName: PropTypes.string,
    searchable: PropTypes.bool,
    sortable: PropTypes.bool,
    name: PropTypes.string,
    type: PropTypes.string,
    dropDownValues: TypeLabelValue,
    filterByAutocomplete: PropTypes.string,
    filterCallBack: PropTypes.func,
    optionsResource: PropTypes.string,
    minWidth: PropTypes.number,
    isMultiple: PropTypes.bool,
    limitTags: PropTypes.number,
    tooltipTitle: PropTypes.string,
  }),
};
export default TableHeadCell;
