import React, { useState, useEffect } from 'react';
import { FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import Switch from '@common/FilterInputs/Switch';
import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';
import { toggleEnabled } from '../../../helpers';
import { useTableContext } from '../../../../../../hooks/table';

import useStyles from './styles';

const BulkAction = ({ mailBoxId }) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const classes = useStyles();

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const list = useSelector(listSelector);

  const { selectedIds, deselectAllItems } = useTableContext();

  useEffect(() => {
    const enabledItems = list.filter(
      item => selectedIds.includes(item.id) && item.isEnabled,
    );

    if (!enabledItems.length || enabledItems.length === selectedIds.length) {
      if (!enabledItems.length) setIsEnabled(false);
      else setIsEnabled(true);
      setDisabled(false);
    } else {
      enqueueSnackbar(
        "You can't select parsers with different statuses of Enabled",
        { variant: 'warning' },
      );
      setDisabled(true);
    }
  }, [selectedIds, list, enqueueSnackbar]);

  const handleChange = async () => {
    await Promise.all(
      selectedIds.map(async id => toggleEnabled(id, mailBoxId, !isEnabled)),
    );
    const newList = list.map(item =>
      selectedIds.includes(item.id) ? { ...item, isEnabled: !isEnabled } : item,
    );
    dispatch(actions.saveList(newList));
    setIsEnabled(!isEnabled);
    deselectAllItems();
  };
  return (
    <>
      <FormControlLabel
        classes={{ label: classes.bulkActionsLabel }}
        control={
          <Switch
            checked={isEnabled}
            disabled={disabled}
            onChange={handleChange}
          />
        }
        label={`${!isEnabled ? 'Enabled' : 'Disabled'}`}
        labelPlacement="start"
      />
    </>
  );
};

BulkAction.propTypes = {
  mailBoxId: PropTypes.string,
};

export default BulkAction;
