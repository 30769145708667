import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Tooltip } from '@material-ui/core';
import cx from 'classnames';

const useStyles = makeStyles(() => ({
  colorBlock: {
    fontWeight: 'bold',
    color: props => props.color,
    backgroundColor: props => props.backgroundColor,
    padding: '5px 10px',
    borderRadius: '0.5rem',
  },
  tooltip: {
    cursor: 'pointer',
  },
}));

const StatusesAccessor = ({ item, array }) => {
  const { name, color, backgroundColor = '', label, title = '' } = array[
    item ?? 0
  ];
  const { colorBlock, tooltip } = useStyles({ color, backgroundColor });

  return (
    <Tooltip title={title} className={cx({ [tooltip]: title })}>
      <span className={colorBlock}>{name || label}</span>
    </Tooltip>
  );
};

StatusesAccessor.propTypes = {
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  array: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.arrayOf(PropTypes.any),
  ]),
};

export default StatusesAccessor;
