import { getHeaders } from '@utils/transfered';
import { makeRequest } from '@services/requests/makeRequest';
import { getAccessToken } from './user';

export const sendFormData = (url, formData) =>
  fetch(url, {
    method: 'POST',
    headers: {
      ...getHeaders(true),
      Authorization: `Bearer ${getAccessToken()}`,
    },
    body: formData,
  });

export const downLoadFile = url =>
  fetch(url, {
    method: 'GET',
    headers: {
      ...getHeaders(true),
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

export const globalExportFile = async (
  globalExportType,
  globalExportFileType = 'csv',
  filters,
) => {
  try {
    const globalExportFields = await makeRequest(
      'GET',
      `/GlobalExport/getFields?globalExportType=${globalExportType}`,
    );
    const validFilters = await makeRequest(
      'GET',
      `/GlobalExport/getFilters?globalExportType=${globalExportType}`,
    );

    const params = {};
    validFilters.forEach(filter => {
      const filterName = filter.charAt(0).toLowerCase() + filter.slice(1);
      if (filters[filterName]) {
        params[filterName] = filters[filterName];
      }
    });

    const queryString = new URLSearchParams({
      globalExportType,
      globalExportFields,
      globalExportFileType,
      ...params,
    }).toString();

    const response = await makeRequest(
      'GET',
      `/GlobalExport?${queryString}`,
      undefined,
      undefined,
      false,
    );
    return response.text();
  } catch (err) {
    throw new Error(err);
  }
};

export const uploadFile = async (url, formData) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        ...getHeaders(true),
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.text();

    return data;
  } catch (error) {
    console.error('Error during file upload:', error);
    return null;
  }
};
