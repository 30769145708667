import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  rootClass: {
    paddingLeft: theme.spacing(0.75),
    paddingRight: theme.spacing(0.75),
    '& > div > button.MuiButtonBase-root': {
      padding: theme.spacing(0.625),
    },
    '& > div > div.app-loader': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    '& svg path': {
      color: theme.palette.appBar.icon,
    },
  },
  toolbar: {
    width: '100%',
    position: 'static',
    paddingLeft: 0,
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  logo: {
    height: '45px',
    maxWidth: '200px',
    objectFit: 'contain',
  },
}));

export default useStyles;
