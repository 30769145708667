import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'right',
  },
  icon: {
    margin: '0 10px',
    transition: '0.3s',

    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.2)',
    },
  },
  tableWrapper: {
    margin: '8px 16px 0',
    '& tbody': {
      maxHeight: '64vh',
    },
  },
}));

export default useStyles;
