import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useTranslate } from 'react-admin';
import useAsync from '@services/api/common/useAsync';
import { getFtpConnections } from '@components/Audit/helpers';
import DropDown from '@components/Auth/Common/DropDown';
import xlsIcon from '@assets/exports/xls.svg';
import csvIcon from '@assets/exports/csv.svg';
import jsonIcon from '@assets/exports/json.svg';
import Loading from '@common/Loading/Loading';
import Card from './Card';
import { postFtpUpload } from './helpers';

import useStyles from './styles';

const items = [
  { name: 'xlsx', icon: xlsIcon },
  { name: 'csv', icon: csvIcon },
  { name: 'json', icon: jsonIcon },
];

const FtpExport = ({ resource }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const translate = useTranslate();

  const [options, setOptions] = useState([]);
  const [selectedConn, setSelectedConn] = useState(null);
  const [process, setProcess] = useState(false);
  const loading = useAsync(getFtpConnections, setOptions);

  const onClick = value => {
    if (!selectedConn) {
      enqueueSnackbar('Select FTP connection first', { variant: 'error' });
      return;
    }
    const payload = {
      ftpConnectionId: selectedConn,
      exportModel: {
        type: 'DeviceMonitors',
        ...value,
      },
    };
    setProcess(true);
    postFtpUpload(payload)
      .then(() =>
        enqueueSnackbar(`${value.fileName} successfully uploaded`, {
          variant: 'success',
        }),
      )
      .catch(e => enqueueSnackbar(e.message, 'error'))
      .finally(() => setProcess(false));
  };

  return (
    <>
      <div className={classes.container}>
        <div>{translate('resources.exporting.ftp.header')}</div>
        <div className={classes.connection}>
          <DropDown
            label="FTP Connection"
            disabled={loading}
            options={options}
            input={{
              value: selectedConn,
              onChange: event => setSelectedConn(event.target.value),
            }}
          />
        </div>
        <div
          className={classes.displayFlex}
          style={{ filter: selectedConn ? 'none' : 'grayscale(1)' }}
        >
          {items.map(item => (
            <Card
              key={item.name}
              data={resource[item.name]}
              icon={
                <img
                  src={item.icon}
                  alt={item.name}
                  className={classes.iconWidth}
                />
              }
              onClick={onClick}
            />
          ))}
        </div>
      </div>
      {process && <Loading />}
    </>
  );
};

FtpExport.propTypes = {
  resource: PropTypes.objectOf(PropTypes.any),
};

export default FtpExport;
