import React, { useEffect, useCallback, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'react-admin';
import { useSnackbar } from 'notistack';

import {
  ThunkGetEmailTestingTypes,
  ThunkTestEmail,
} from '@store/slices/testEmail/thunks';
import { requiredValidator } from '@utils/validators';

import DropDown from '../Auth/Common/DropDown';
import Input from '../Auth/Common/Input';
import ReusableButton from '../common/Button/Button';
import FieldWrapper from '../common/form/FieldWrapper';
import Loading from '../common/Loading/Loading';

import useStyles from './styles';

const TestEmail = () => {
  const [isProcessing, setProcessing] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const translate = useTranslate();

  const { types, isLoading } = useSelector(state => state.testEmail);

  const onMount = useCallback(async () => {
    dispatch(ThunkGetEmailTestingTypes());
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const submit = values => {
    setProcessing(true);
    dispatch(ThunkTestEmail(values))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Successfully sent', { variant: 'success' });
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const validation = values => {
    return {
      type: requiredValidator(values.type)
        ? translate('ra.validation.required')
        : undefined,
      email: requiredValidator(values.email)
        ? translate('ra.validation.required')
        : undefined,
    };
  };

  if (isLoading) return <Loading />;
  return (
    <div className={classes.container}>
      <div>Test Emails</div>
      <Form
        onSubmit={submit}
        validate={validation}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FieldWrapper
              label="Type"
              labelSize={12}
              contentSize={12}
              showLabel
              isRequired
              classNameLabelInner={classes.alignLeft}
              content={
                <Field
                  name="type"
                  id="type"
                  render={DropDown}
                  options={types}
                  disabled={isProcessing}
                />
              }
            />

            <FieldWrapper
              label="Email"
              labelSize={12}
              contentSize={12}
              showLabel
              isRequired
              classNameLabelInner={classes.alignLeft}
              content={<Field name="email" id="email" render={Input} />}
            />

            <div className={classes.actions}>
              <ReusableButton
                label="Test"
                type="submit"
                loading={isProcessing}
                disabled={isProcessing}
              />
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default TestEmail;
