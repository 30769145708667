import React from 'react';

import useStyles from './styles';

const PodPlaceholder = () => {
  const classes = useStyles();

  return (
    <div className={classes.textContainer}>
      Support for this pod has been deprecated. Please contact our support team
      at
      <a
        href="mailto:help@support.mspprocess.com"
        className={classes.textWraps}
      >
        help@support.mspprocess.com
      </a>
      to migrate to the new version of the pod, so that your team is not
      impacted.
    </div>
  );
};

export default PodPlaceholder;
