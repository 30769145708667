import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useSafeSetState, useTranslate } from 'react-admin';
import { useSnackbar } from 'notistack';
import Input from '@common/Input/Input';
import TextMask from '@components/Auth/Common/creditCardInput';
import visa from '@assets/billing/visa.svg';
import masterCard from '@assets/billing/mastercard.svg';
import americanExpress from '@assets/billing/americanExpress.svg';
import ReusableButton from '@common/Button/Button';
import useValidate from '@components/Hooks/useValidate';
import { updateCardInfo } from './helpers';
import useStyles from './styles';

const Card = ({ authKey }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useSafeSetState(false);

  const {
    required,
    numberInRange,
    numberLengthRange,
    validateCreditCardNumber,
  } = useValidate();

  const validate = values => {
    const errors = {};
    errors.cardHolder = required(values.cardHolder);
    errors.cardNumber = validateCreditCardNumber(values.cardNumber);
    errors.cardExpMonth = numberInRange(values.cardExpMonth, 1, 12);
    errors.cardExpYear = numberInRange(values.cardExpYear, 22, 35);
    errors.cardCvc = numberLengthRange(values.cardCvc, 3, 4);
    return errors;
  };

  const submit = values => {
    setLoading(true);
    const payload = {
      ...values,
      cardExpMonth: +values.cardExpMonth,
      cardExpYear: +values.cardExpYear,
    };
    updateCardInfo(authKey, payload)
      .then(() => {
        enqueueSnackbar('Information successfully updated', {
          variant: 'success',
        });
      })
      .catch(e => enqueueSnackbar(e.message || e, { variant: 'error' }))
      .finally(() => setLoading(false));
  };

  return (
    <div className={classes.container}>
      <div>
        <span className={classes.font}>
          {translate('resources.billing.creditCard.content.header')}
        </span>
      </div>
      <div className={`${classes.block} ${classes.smallBlock}`}>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div>
                <Field
                  id="cardHolder"
                  name="cardHolder"
                  inputView="text"
                  styleType="main"
                  fullWidth
                  component={Input}
                  placeholder={translate('ra.creditCard.name')}
                  disabled={loading}
                />
                <Field
                  id="cardNumber"
                  name="cardNumber"
                  inputView="text"
                  styleType="main"
                  fullWidth
                  component={Input}
                  classNameWrapper={classes.inputWrapper}
                  InputProps={{ inputComponent: TextMask }}
                  disabled={loading}
                />
                <div className={classes.inputGroup}>
                  <Field
                    id="cardExpMonth"
                    name="cardExpMonth"
                    inputView="text"
                    styleType="main"
                    component={Input}
                    placeholder={translate('ra.creditCard.month')}
                    disabled={loading}
                  />
                  <Field
                    id="cardExpYear"
                    name="cardExpYear"
                    inputView="text"
                    styleType="main"
                    component={Input}
                    placeholder={translate('ra.creditCard.year')}
                    disabled={loading}
                  />
                  <Field
                    id="cardCvc"
                    name="cardCvc"
                    inputView="text"
                    styleType="main"
                    component={Input}
                    placeholder={translate('ra.creditCard.cvv')}
                    disabled={loading}
                  />
                </div>
                <div className={classes.imageGroup}>
                  <img src={visa} alt="" />
                  <img src={masterCard} alt="" />
                  <img src={americanExpress} alt="" />
                </div>
              </div>
              <div className={classes.buttonWrapper}>
                <ReusableButton
                  size="md"
                  viewType="black"
                  type="submit"
                  classNameWrapper={classes.button}
                  disabled={loading}
                  loading={loading}
                  label="resources.billing.updateButton"
                />
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
};

Card.propTypes = {
  authKey: PropTypes.string,
};

export default Card;
