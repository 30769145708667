import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Dialog, DialogContent, Typography } from '@material-ui/core';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import { Field, Form } from 'react-final-form';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { generateCode, updateItems } from './helpers';
import couponTypes from './couponTypes';

import useStyles from './styles';

const initialValues = {
  id: 0,
  couponCode: generateCode(),
  type: couponTypes[0].value,
  durationMonth: 1,
  amount: 0,
};

const CouponForm = ({ open, onClose, onSubmit, data }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const init = data || initialValues;
  init.couponCode = init.id ? init.couponCode : generateCode();

  const validate = values => {
    const couponRegEx = /^[0-9A-Za-z-]+$/gms;

    return {
      couponCode: !couponRegEx.test(values.couponCode)
        ? 'Invalid coupon code format'
        : undefined,
    };
  };

  const submit = values => {
    setLoading(true);
    updateItems(values)
      .then(d => onSubmit(d))
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setLoading(false));
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogContent>
        <div className={classes.textCenter}>
          <Typography className={classes.title}>
            {init.id ? 'Update' : 'Create'}
            &nbsp; Coupon
          </Typography>
          <Form
            onSubmit={submit}
            validate={validate}
            initialValues={init}
            render={({ handleSubmit, form, values }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className={classes.componentsContainer}>
                  <Field
                    id="couponCode"
                    label="Dealer Code"
                    name="couponCode"
                    styleType="main"
                    inputView="text"
                    fullWidth
                    component={Input}
                    disabled={loading}
                  />

                  <div>
                    <Typography>Coupon Type</Typography>
                    <RadioGroup
                      row
                      id="type"
                      name="type"
                      fullWidth
                      onChange={e => form.change('type', e.target.value)}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                      }}
                    >
                      {couponTypes.map(choice => (
                        <FormControlLabel
                          key={choice.value}
                          value={choice.value}
                          checked={values.type === choice.value}
                          label={choice.label}
                          control={<Radio />}
                        />
                      ))}
                    </RadioGroup>
                  </div>
                </div>
                <div className={classes.switchContainer}>
                  <Typography>Amount</Typography>
                  <Field
                    id="amount"
                    name="amount"
                    component={Input}
                    type="number"
                    className={classes.couponInput}
                    dateProps={{ min: 0, max: 5000 }}
                  />
                </div>
                <div className={classes.switchContainer}>
                  <Typography>Coupon duration (in months)</Typography>
                  <Field
                    id="durationMonth"
                    name="durationMonth"
                    component={Input}
                    type="number"
                    className={classes.couponInput}
                    dateProps={{ min: 1, max: 36 }}
                  />
                </div>
                <div className={classes.marginTop}>
                  <ReusableButton
                    size="md"
                    type="button"
                    disabled={loading}
                    loading={loading}
                    label="common.cancel"
                    onClick={onClose}
                  />
                  <ReusableButton
                    size="md"
                    viewType="black"
                    type="submit"
                    disabled={loading}
                    loading={loading}
                    label={init.id ? 'common.update' : 'common.create'}
                  />
                </div>
              </form>
            )}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

CouponForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    couponCode: PropTypes.string,
    type: PropTypes.number,
    unlimited: PropTypes.bool,
  }),
};

export default CouponForm;
