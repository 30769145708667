import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  container: {
    margin: '20px 16px 0',
    '& tbody': {
      maxHeight: '73vh',
    },
    borderRadius: '15px',
  },
  buttonMargin: {
    margin: '0 0.3rem',
  },
  tableActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    margin: '1rem 2rem',
  },
  configSelector: {
    width: '25rem',
  },
  tableActionsWrapper: {
    marginBottom: '0.5rem',
  },
  selectLanguage: {
    marginLeft: '5.5rem',
    fontFamily: "'Montserrat'",
  },
  dialogTemplate: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
  },
  loadingWrapper: {
    height: '300px',
  },
  list: {
    maxHeight: '450px',
    overflow: 'scroll',
  },
  switch: {
    '& .MuiFormControl-root': {
      border: 'none',
    },
  },
  checkBox: {
    background: '#80808026',
    borderRadius: '5px',
    paddingLeft: '8px',
    margin: '8px 0',
    width: '99%',
    '& path': {
      fill: '#1b3670',
    },
  },
  wrapper: {
    maxWidth: '99%',
  },
  fieldWrapper: {
    padding: '0 40px',
    alignItems: 'center',
  },
  mainWrapper: {
    width: '100%',
  },
  disabled: {
    opacity: ' 0.5',
  },
  domainField: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    color: '#9f9f9f',
  },
  domainView: {
    wordBreak: 'break-word',
  },
  domainName: {
    color: '#000',
  },
  fileUploader: {
    '& img': {
      minWidth: '150px',
    },
  },
}));

export default useStyles;
