import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    gridTemplateRows: '50px 1fr 100px',
    height: props => (props.fromPodPage ? '70vh' : '85vh'),
    boxSizing: 'border-box',
    margin: '20px',
    overflow: 'hidden',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    overflowY: 'auto',
    height: '100%',
    backgroundColor: '#dedede',
    padding: '10px',
    boxSizing: 'border-box',
    borderRadius: '10px',

    '& > div': {
      width: '50%',
      padding: '15px',
      boxSizing: 'border-box',
      backgroundColor: 'white',
      borderRadius: '10px',
    },
    '& .ref': {
      visibility: 'hidden',
    },
  },
  clientMessage: {
    alignSelf: 'flex-end',
    backgroundColor: '#0167e9 !important',
    color: 'white',
    '& .dateTime': {
      color: 'white !important',
      textAlign: 'right',
    },
  },
  date: {
    color: '#0000006b',
  },
  name: {
    fontWeight: '800',
  },
  chatInput: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '70px 1fr 70px',
    padding: '10px',
    boxSizing: 'border-box',
    width: '75%',
    justifyItems: 'center',
    '@media (max-height: 650px)': {
      width: '100%',
    },
  },
  attachment: {
    backgroundColor: 'transparent !important',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 'unset !important',
    flexDirection: 'column',
    '& .file': {
      background: 'white',
      padding: '20px',
      cursor: 'pointer',
      borderRadius: '10px',
    },
    '& .dateTime': {
      margin: '5px 10px',
    },
  },
  attachmentClient: {
    backgroundColor: 'transparent !important',
    display: 'flex',
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    padding: 'unset !important',
    flexDirection: 'column',
    '& .file': {
      background: '#0167e9',
      padding: '20px',
      cursor: 'pointer',
      borderRadius: '10px',
    },
    '& .dateTime': {
      margin: '5px 10px',
      textAlign: 'right',
    },
  },
  validationError: {
    display: 'block',
    height: '0.8rem',
    marginLeft: '5rem',
    color: 'red',
  },
}));

export default useStyles;
