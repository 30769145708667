import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Switch from '@common/FilterInputs/Switch';
import ReusableButton from '@common/Button/Button';

import { DATA_EXPORT, DATA_VIEW_EDIT } from '@constants/routes';
import { TableContext } from '@components/Table/context';

import useStyles from './styles';

const AuditPageTableActions = ({ dataViewId }) => {
  const [hideAcknowledged, setHideAcknowledged] = useState(false);

  const classes = useStyles();
  const { setFilteringObject, filtering, data } = useContext(TableContext);
  const { push } = useHistory();

  const handleHideAcknowledged = () => {
    setFilteringObject({
      ...filtering,
      hideAcknowledged: !hideAcknowledged,
    });
    setHideAcknowledged(!hideAcknowledged);
  };

  const disableExport = useMemo(() => !data?.length, [data]);

  const handleEditDataView = () => {
    push(DATA_VIEW_EDIT.replace(':dataViewId', dataViewId));
  };

  const handleExport = () => {
    push(DATA_EXPORT);
  };

  const handleRefresh = () => {
    setFilteringObject(filtering);
  };

  return (
    <>
      <FormControlLabel
        label="Hide Acknowledged"
        style={{ alignItems: 'flex-end' }}
        labelPlacement="start"
        control={
          <Switch
            checked={hideAcknowledged}
            onChange={handleHideAcknowledged}
            name="hideAcknowledged"
            color="primary"
          />
        }
      />

      <div className={classes.buttons}>
        <ReusableButton label="Edit Data View" onClick={handleEditDataView} />
        <ReusableButton
          label="Export"
          onClick={handleExport}
          disabled={disableExport}
          toolTip={
            disableExport ? 'The list is empty. Nothing to export.' : undefined
          }
        />
        <ReusableButton label="Refresh" onClick={handleRefresh} />
      </div>
    </>
  );
};

AuditPageTableActions.propTypes = {
  dataViewId: PropTypes.string.isRequired,
};

export default AuditPageTableActions;
