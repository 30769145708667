import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  form: {
    padding: '20px',
    boxSizing: 'border-box',
    width: '50vw',
    maxWidth: '800px',
    minWidth: '500px',
    margin: '20px auto',
    background: 'white',
    borderRadius: '10px',
  },
}));

export default useStyles;
