import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { useSnackbar } from 'notistack';

import {
  ThunkCreateOrUpdateTeamsBotConfig,
  ThunkDownloadTeamsBotZip,
  ThunkGetTeamsBotConfig,
  ThunkRemoveTeamsBotImage,
  ThunkUploadImage,
} from '@store/slices/microsoft/thunks';

import { getTenantIdFromStorage } from '@services/api';
import { requiredValidator } from '@utils/validators';

import Loading from '@common/Loading/Loading';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import FileUploader from '@components/LiveChatConfigurations/components/FileUploader';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

import useStyles from './styles';

const TeamsBotConfiguration = () => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [config, setConfig] = useState(undefined);
  const [processingZip, setProcessingZip] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const { enqueueSnackbar } = useSnackbar();

  const downloadZip = () => {
    setProcessingZip(true);
    dispatch(ThunkDownloadTeamsBotZip())
      .unwrap()
      .finally(() => {
        setProcessingZip(false);
      });
  };

  const submit = values => {
    setProcessing(true);

    dispatch(ThunkCreateOrUpdateTeamsBotConfig(values))
      .unwrap()
      .then(() => {
        enqueueSnackbar(
          `Configuration ${!config ? 'created' : 'updated'} successfully`,
          {
            variant: 'success',
          },
        );

        if (!config) {
          // when we create a new config, we need to change this state so client download Zip and update the config
          setConfig(values);
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const validate = values => ({
    name: requiredValidator(values.name),
    descriptionShort: requiredValidator(values.descriptionShort),
    descriptionLong: requiredValidator(values.descriptionLong),
    webSiteUrl: requiredValidator(values.webSiteUrl),
    privacyUrl: requiredValidator(values.privacyUrl),
    termsOfServiceUrl: requiredValidator(values.termsOfServiceUrl),
  });

  const onMount = useCallback(() => {
    setLoading(true);
    dispatch(ThunkGetTeamsBotConfig())
      .unwrap()
      .then(res => {
        setLoading(false);
        if (typeof res === 'object') {
          setConfig(res);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <Form
      onSubmit={submit}
      initialValues={config}
      validate={validate}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <h3>Teams Bot Configuration</h3>
          <FieldWrapper
            label="Logo"
            labelSize={12}
            contentSize={12}
            fullWidth
            content={
              <FileUploader
                name="logo"
                resourceId={getTenantIdFromStorage()}
                handleUpload={ThunkUploadImage}
                handleRemove={ThunkRemoveTeamsBotImage}
                imageWidth="300px"
                onUploadCallBack={res => {
                  form.change('logo', res);
                }}
              />
            }
          />

          <FieldWrapper
            label="Name"
            labelSize={12}
            contentSize={12}
            fullWidth
            isRequired
            content={
              <Field
                placeholder="Name"
                name="name"
                id="name"
                render={Input}
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Description (short)"
            labelSize={12}
            contentSize={12}
            fullWidth
            isRequired
            content={
              <Field
                placeholder="Description (short)"
                name="descriptionShort"
                id="descriptionShort"
                render={Input}
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Description (long)"
            labelSize={12}
            contentSize={12}
            fullWidth
            isRequired
            content={
              <Field
                placeholder="Description (long)"
                name="descriptionLong"
                id="descriptionLong"
                multiline
                minRows={3}
                maxRows={3}
                render={Input}
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Website URL"
            labelSize={12}
            contentSize={12}
            fullWidth
            isRequired
            content={
              <Field
                placeholder="Website URL"
                name="webSiteUrl"
                id="webSiteUrl"
                render={Input}
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Privacy URL"
            labelSize={12}
            contentSize={12}
            fullWidth
            isRequired
            content={
              <Field
                placeholder="Privacy URL"
                name="privacyUrl"
                id="privacyUrl"
                render={Input}
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Terms of service URL"
            labelSize={12}
            contentSize={12}
            fullWidth
            isRequired
            content={
              <Field
                placeholder="Terms of service URL"
                name="termsOfServiceUrl"
                id="termsOfServiceUrl"
                render={Input}
                disabled={processing}
              />
            }
          />

          <div className={sharedClasses.dialogActionsContainer}>
            {config && (
              <ReusableButton
                label="Get Zip File"
                onClick={downloadZip}
                disabled={processing || processingZip}
                loading={processingZip}
              />
            )}
            <ReusableButton
              disabled={processing || processingZip}
              loading={processing}
              label={config ? 'Update' : 'Create'}
              type="submit"
              viewType="blue"
            />
          </div>
        </form>
      )}
    />
  );
};

export default TeamsBotConfiguration;
