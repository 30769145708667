import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Modal from '@ui/common/Modal/Modal';

import nationalFormatPhoneNumber from '@utils/nationalFormatPhoneNumber';

import useStyles from './styles';

const ViewInfoModal = ({ setRowData, rowData }) => {
  const isHasActionsData = !!rowData.actions;
  const classes = useStyles();
  const content = useMemo(() => {
    if (isHasActionsData) {
      return (
        <>
          <div>
            <strong>Actions: </strong>
          </div>
          <ul>
            {rowData.actions.map(action => (
              <li key={action}>{action}</li>
            ))}
          </ul>
        </>
      );
    }
    return (
      <>
        {rowData.details.map(detail => (
          <div key={detail.id} className={classes.detailsWrapper}>
            <div>
              <strong>Tenant id: </strong>
              {detail.tenantId}
            </div>
            <div>
              <strong>Base entity id: </strong>
              {detail.baseEntityId}
            </div>
            <div>
              <strong>Recipient: </strong>
              {nationalFormatPhoneNumber(detail.recipient || '')}
            </div>
            <div>
              <strong>Data: </strong>
              {detail.data}
            </div>
          </div>
        ))}
      </>
    );
  }, [classes, isHasActionsData, rowData]);

  return (
    <Modal open setOpen={setRowData} title="View">
      <div className={classes.contentWrapper}>{content}</div>
    </Modal>
  );
};

ViewInfoModal.propTypes = {
  rowData: PropTypes.shape({
    actions: PropTypes.arrayOf(PropTypes.string),
    details: PropTypes.arrayOf(
      PropTypes.shape({
        tenantId: PropTypes.number,
        baseEntityId: PropTypes.number,
        recipient: PropTypes.string,
        data: PropTypes.string,
      }),
    ),
  }),
  setRowData: PropTypes.func,
};

export default ViewInfoModal;
