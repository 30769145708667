import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import ReusableButton from '@ui/components/common/Button/Button';

import { actions } from '@store/actions';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import { tableDefaultFilters } from '@components/AvailablePhoneNumbers/constants';
import PurchaseFilters from '@components/AvailablePhoneNumbers/components/PurchaseFilters';
import sharedStyles from '@components/CrmTicket/sharedStyles';
import { ThunkBuyPhoneNumber } from '@store/slices/phoneNumbers/thunks';
import InternationalFormatPhoneNumber from '@utils/InternationalFormatPhoneNumber';

const getTypeEnum = type => {
  switch (String(type)) {
    case 'Toll-Free':
      return 'tollFree';
    case 'Local':
      return 'local';
    case 'Mobile':
      return 'mobile';
    default:
      return 'undefined';
  }
};

const AvailablePhoneNumbers = () => {
  const classes = sharedStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const handleBuy = (phoneNumber, country, id, type) => {
    setLoading(true);
    dispatch(
      ThunkBuyPhoneNumber({ phoneNumber, country, type: getTypeEnum(type) }),
    ).then(({ error }) => {
      if (!error) {
        dispatch(actions.removeItemFromList({ id }));
        enqueueSnackbar('The number is purchased', 'success');
      } else {
        enqueueSnackbar(error.message, 'error');
      }
      setLoading(false);
    });
  };

  const columns = [
    {
      name: 'Number',
      key: 'dedicatedNumber',
      isCustomAccessor: true,
      accessor: rowData =>
        InternationalFormatPhoneNumber(rowData.dedicatedNumber),
    },
    {
      name: 'Country',
      key: 'country',
    },
    {
      name: 'Type',
      key: 'type',
    },
    {
      name: 'Monthly Fee',
      key: 'priceMonthly',
    },
    {
      name: 'Setup Fee',
      key: 'priceSetup',
      accessor: rowData => (
        <>
          {rowData.priceSetup === '0.0000' || rowData.priceSetup === null
            ? 'Free'
            : rowData.priceSetup}
        </>
      ),
    },
    {
      name: 'Actions',
      key: 'actions',
      accessor: rowData => (
        <ReusableButton
          label="Buy"
          onClick={() => {
            handleBuy(
              rowData.dedicatedNumber,
              rowData.country,
              rowData.id,
              rowData.type,
            );
          }}
          disabled={loading}
        />
      ),
    },
  ];

  return (
    <div className={classes.container}>
      <MuiVirtualizedTable
        actions={<></>}
        columns={columns}
        resource="BufferPhoneNumbers/buy/availableNumbers"
        defaultFilters={tableDefaultFilters}
      >
        <PurchaseFilters />
      </MuiVirtualizedTable>
    </div>
  );
};

export default AvailablePhoneNumbers;
