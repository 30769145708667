import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import useValidate from '@components/Hooks/useValidate';
import { actions } from '@store/actions';
import { сloneNotification } from '../../helpers';

const useStyles = makeStyles(() => ({
  formText: {
    marginTop: '1rem',
    fontStyle: 'normal',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const MakeCopyDialog = ({ open, onClose, item }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { required } = useValidate();
  const [loading, setLoading] = useState(false);
  const validate = values => {
    return {
      name: required(values.name),
    };
  };

  const submit = values => {
    const payload = {
      id: item.id,
      name: values.name,
    };
    setLoading(true);
    сloneNotification(payload)
      .then(d => dispatch(actions.updateItemInList(d)))
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <Form
        onSubmit={submit}
        validate={validate}
        initialValues={{ name: `${item?.name}_copy` }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate className={classes.form}>
            <DialogTitle id="simple-dialog-title">
              Clone notification set
            </DialogTitle>
            <DialogContent>
              <div className={classes.formContainer}>
                <Field
                  id="name"
                  name="name"
                  fullWidth
                  component={Input}
                  placeholder="Name"
                  disabled={loading}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <ReusableButton
                label="resources.buttons.cancel"
                onClick={onClose}
                disabled={loading}
              />
              <ReusableButton
                type="submit"
                viewType="black"
                label="resources.buttons.confirm"
                loading={loading}
                disabled={loading}
              />
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

MakeCopyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

export default MakeCopyDialog;
