import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ThunkGetTicketLink } from '@store/slices/crmTicket/thunks';
import {
  MessagingChannelCrmInfoSelector,
  MessagingChannelSelector,
  MessagingConfigurationSelector,
} from '@store/slices/messaging';

import crmSources, { crmSourcesEnum } from '@constants/crmSources';
import {
  CRM_TICKET_EDIT,
  CRM_TICKET_SCHEDULE_ENTRIES,
  CRM_TICKET_TIME_ENTRIES,
} from '@constants/routes';

import EditIcon from '@assets/icons/edit.svg';
import CalendarIcon from '@assets/icons/calendar_green.svg';
import CalendarPlus from '@assets/icons/calendar_plus.svg';
import AuditIcon from '@assets/icons/audit.svg';
import AuditPlus from '@assets/icons/audit_plus.svg';

import ActionFieldItem from '@ui/components/common/ActionButtons/ActionFieldItem';
import AddCWTicketTimeEntryDialog from '@ui/components/CrmTicket/dialogs/AddCWTimeEntry';
import AddScheduleEntryDialog from '@ui/components/CrmTicket/dialogs/AddScheduleEntry';
import ChannelDetailsItem from './ChannelDetailsItem';

import useStyles from '../styles';

const CrmSpecificInfo = () => {
  const [openAddTimeEntryDialog, setOpenAddTimeEntryDialog] = useState(false);
  const [openAddScheduleEntryDialog, setOpenAddScheduleEntryDialog] = useState(
    false,
  );

  const classes = useStyles();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const crmInfo = useSelector(MessagingChannelCrmInfoSelector);
  const channel = useSelector(MessagingChannelSelector);
  const configuration = useSelector(MessagingConfigurationSelector);

  const handleEditRedirect = () => {
    push(
      CRM_TICKET_EDIT.replace(':crmDefaultId', configuration.crmDefaultId)
        .replace(':ticketId', channel.ticketId)
        .replace(':crmName', crmSourcesEnum[configuration.crmType]),
    );
  };

  const handleTimeEntriesRedirect = () => {
    push(
      CRM_TICKET_TIME_ENTRIES.replace(
        ':crmDefaultId',
        configuration.crmDefaultId,
      ).replace(':ticketId', channel.ticketId),
    );
  };

  const handleScheduleEntriesRedirect = () => {
    push(
      CRM_TICKET_SCHEDULE_ENTRIES.replace(
        ':crmDefaultId',
        configuration.crmDefaultId,
      ).replace(':ticketId', channel.ticketId),
    );
  };

  const handleTicketIdClick = async (crmType, ticketId) => {
    dispatch(
      ThunkGetTicketLink({
        ticketId,
        crmDefaultId: configuration.crmDefaultId,
      }),
    )
      .unwrap()
      .then(res => {
        window.open(res.data, '_blank');
      });
  };

  return (
    <div className={classes.box}>
      <div className={classes.boxHeader}>
        Ticket Information
        <div>
          <ActionFieldItem
            alt="Edit"
            handler={handleEditRedirect}
            toolTip="Edit"
            icon={EditIcon}
          />
          {configuration.crmName === crmSources.ConnectWise.label && (
            <>
              <ActionFieldItem
                alt="Time Entries"
                handler={handleTimeEntriesRedirect}
                toolTip="Time Entries"
                icon={CalendarIcon}
              />
              <ActionFieldItem
                alt="Add Time Entry"
                handler={() => {
                  setOpenAddTimeEntryDialog(true);
                }}
                toolTip="Add Time Entry"
                icon={CalendarPlus}
              />

              <ActionFieldItem
                alt="Schedule Entries"
                handler={handleScheduleEntriesRedirect}
                toolTip="Schedule Entries"
                icon={AuditIcon}
              />
              <ActionFieldItem
                alt="Add Schedule Entry"
                handler={() => {
                  setOpenAddScheduleEntryDialog(true);
                }}
                toolTip="Add Schedule Entry"
                icon={AuditPlus}
              />
            </>
          )}
        </div>
      </div>

      <div>
        {channel && configuration && (
          <div className={classes.itemContainer}>
            <div>Ticket ID</div>
            <div
              onClick={() => {
                handleTicketIdClick(configuration.crmType, channel.ticketId);
              }}
              onKeyDown={() => {}}
              role="presentation"
              className={classes.ticketIdLink}
            >
              {channel.ticketNumber || channel.ticketId}
            </div>
          </div>
        )}
        {crmInfo?.data?.map(info => (
          <ChannelDetailsItem
            key={info.key}
            label={info.key}
            value={info.value}
          />
        ))}
      </div>

      {openAddTimeEntryDialog && (
        <AddCWTicketTimeEntryDialog
          open={openAddTimeEntryDialog}
          setOpen={setOpenAddTimeEntryDialog}
          crmDefaultId={configuration.crmDefaultId}
          ticketId={channel.ticketId}
        />
      )}

      {openAddScheduleEntryDialog && (
        <AddScheduleEntryDialog
          open={openAddScheduleEntryDialog}
          setOpen={setOpenAddScheduleEntryDialog}
          crmDefaultId={configuration.crmDefaultId}
          ticketId={channel.ticketId}
        />
      )}
    </div>
  );
};

export default CrmSpecificInfo;
