import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { sanitizeListRestProps, TopToolbar, useTranslate } from 'react-admin';

import ReusableButton from '@common/Button/Button';
import ExportButton from '@common/buttons/ExportButton/ExportButton';

import uploadIcon from '@assets/icons/upload_white.svg';
import refreshIcon from '@assets/icons/refresh.svg';
import { useTableContext } from '../../../../../hooks/table';

import useStyles from './styles';

const ListActions = props => {
  const classes = useStyles();
  const {
    className,
    exporter,
    filters,
    maxResults,
    onRefresh,
    onUploadOpen,
    mailBoxId,
    globalExportType,
    ...rest
  } = props;

  const { filtering, list } = useTableContext();

  const translate = useTranslate();

  return (
    <TopToolbar
      className={cx(classes.toolBar, className)}
      {...sanitizeListRestProps(rest)}
    >
      <ExportButton
        disabled={list.length === 0}
        globalExportType={globalExportType}
        filters={{
          mailBoxId,
          ...filtering,
        }}
      />
      {onUploadOpen && (
        <ReusableButton
          size="md"
          viewType="black"
          onClick={onUploadOpen}
          icon={null}
          classNameWrapper={classes.buttonWrapper}
        >
          <>
            <img src={uploadIcon} alt="upload" />
            {translate('common.upload')}
          </>
        </ReusableButton>
      )}
      <ReusableButton
        size="md"
        classNameWrapper={classes.buttonWrapper}
        onClick={onRefresh}
      >
        <>
          <img src={refreshIcon} alt="refresh" />
          {translate('common.refresh')}
        </>
      </ReusableButton>
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  exporter: PropTypes.bool,
  filters: PropTypes.objectOf(PropTypes.any),
  maxResults: PropTypes.number,
  onRefresh: PropTypes.func,
  mailBoxId: PropTypes.number,
  globalExportType: PropTypes.string,
  onUploadOpen: PropTypes.func,
};

export default ListActions;
