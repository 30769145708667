import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useTranslate, useRefresh } from 'react-admin';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import { actions } from '@store/actions';
import ParsersList from './Components/ParsersList';
import LettersList from './Components/LettersList';
import { deleteParser } from '../../helpers';

const Parsers = ({ mailBoxId }) => {
  const refresh = useRefresh();
  const { enqueueSnackbar } = useSnackbar();
  const translate = useTranslate();
  const dispatch = useDispatch();

  const [currentParserId, setCurrentParserId] = useState(0);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const onParserSelect = e => setCurrentParserId(e.id);
  const goBack = () => setCurrentParserId(0);

  const onDelete = value => {
    setDeleteId(value.id);
    setDeleteDialogOpen(true);
  };

  const onCancelDeleteHandler = () => setDeleteDialogOpen(false);

  const onDeleteHandler = async () => {
    const result = await deleteParser(deleteId);
    if (!result) {
      enqueueSnackbar(translate('resources.parsers.deleteDialog.error'), {
        variant: 'warning',
      });
    } else {
      dispatch(actions.removeItemFromList({ id: deleteId }));
      refresh();
    }
    setDeleteDialogOpen(false);
    setDeleteId(null);
  };

  return (
    <div>
      {currentParserId === 0 ? (
        <ParsersList
          onParserSelect={onParserSelect}
          onDelete={onDelete}
          mailBoxId={mailBoxId}
        />
      ) : (
        <LettersList
          parserId={currentParserId}
          goBack={goBack}
          mailBoxId={mailBoxId}
        />
      )}
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={onDeleteHandler}
        onCancel={onCancelDeleteHandler}
        title={translate('resources.parsers.deleteDialog.head')}
        content={translate('resources.parsers.deleteDialog.body')}
      />
    </div>
  );
};

Parsers.propTypes = {
  mailBoxId: PropTypes.string.isRequired,
};

export default Parsers;
