export const CRM_TICKETS = '/CrmTickets';
export const INCIDENTS = '/incidents';
export const INCIDENTS_NOTES = '/IncidentNotes';
export const INCIDENT_TIME_ENTRY = '/IncidentTimeEntry';
export const TEST_EMAIL = '/TestEmails';
export const CHATS = '/Chat';
export const LIVE_CHAT_ADMIN = '/LiveChatAdmin';
export const PHONE_NUMBERS = '/MessagingPhoneNumbers';
export const BUFFER_PHONE_NUMBERS = '/BufferPhoneNumbers';
export const MESSAGING_CONFIGURATIONS = '/MessagingConfigurations';
export const MESSAGING_CONFIGURATIONS_DEFAULT_VALUES =
  '/MessagingConfigurationDefaultValues';
export const MESSAGING_BOARD = '/MessagingBoard';
export const CRM_DEFAULTS_TICKETS = '/CrmTicketDefaults';
export const MESSAGING_TEMPLATES = '/MessagingTemplates';
export const ADMIN = '/Admin';
export const MESSAGING_DASHBOARD = '/MessagingDashboard';
export const TRIAGE = '/Triage';
export const INTEGRATION = '/Integration';
export const M365INTEGRATION = '/MicrosoftContactsIntegrations';
export const TICKET_UPDATE = '/TicketUpdate';
export const USER = '/User';
export const COMMON = '/Common';
export const NOTIFICATION_GROUPS = '/NotificationGroups';
export const SEND_SMS = '/SendSms';
export const TEAMS_CONNECTIONS = '/TeamsConnections';
export const SMS_CONVERSATIONS = '/SmsConversations';
export const LIVE_CHAT_CONVERSATIONS = '/LiveChatConversations';
export const CONNECTWISE_POD = '/ConnectwisePod';
export const PASSWORDS = '/Passwords';
export const VERIFICATION_SETTINGS = '/VerificationSettings';
export const TICKET_TIMER = '/TicketTimer';
export const CRM_SOURCE = '/CrmSource';
export const SMS_BLACK_LIST = 'SmsBlackList';
export const SMS_BLOCKED = 'BlockedSms';
export const OPT_IN = '/OptIn';
export const PSA_CONTACTS = 'PsaContacts';
export const AUTOTASK_POD = '/AutotaskPod';
export const SUBSCRIPTIONS = '/Subscriptions';
export const AUTOTASK_FILE_UPLOAD = '/AutotaskUpload';
export const CREATE_TICKET = '/TicketCreate';
export const TICKET_CREATION_TEMPLATES = '/TicketCreationTemplates';
export const KASEYA_TICKET = '/TicketUpdateKas';
export const KASEYA_POD = '/KaseyaPod';
export const HALO_POD = '/HaloPod';
export const LIVE_CHAT_CHAT_BOTS = '/LiveChatBot';
export const CLIENT_PORTAL_ADMIN = '/ClientPortal';
export const CLIENT_PORTAL_CHATS = '/ClientPortalChats';
export const CLIENT_PORTAL_USERS = '/ClientPortalUsers';
export const CLIENT_PORTAL_DEFAULTS = '/ClientPortalDefaults';
export const ISSUE_TYPES = '/ClientPortalIssueTypes';
export const PSA_CONTACT_CLIENT_PORTAL = '/PsaContactClientPortal';
export const PSA_CONTACT_VALIDATION = '/PsaContactValidation';
export const TENANTS = '/Tenants';
export const SECURE_DATA = '/SecureData';
export const USERS = '/Users';
export const SMS_CAMPAIGN_GROUPS = '/SmsCampaignGroup';
export const BILLING = '/Billing';
export const TOKEN = '/Token';
export const PRICE_PLAN = '/PricePlan';
export const OUTBOUND_MAIL_BOX = '/OutboundMailbox';
export const SECURE_DATA_SETTINGS = '/SecureDataSettings';
export const COMPANY_VERIFICATION_SETTINGS = '/CompanyVerificationSettings';
export const OPTIN_SETTINGS = '/OptInSettings';
export const VERIFIED_PSA_TECH_BY_SMS = '/VerifiedPsaTechBySms';
export const EMAIL_TEMPLATE_SETTINGS = '/EmailTemplatesSettings';
export const GENERIC_SOURCES = '/genericSources';
export const LOG_RECORDS = '/LogRecords';
export const HELPDESK_AI_ASSISTANTS = '/HelpdeskAiAssistants';
export const AI_PRICE_PLAN = '/HelpdeskAiPricePlan';
export const TEAMS_BOT_CONFIGURATION = '/TeamsBotConfig';
