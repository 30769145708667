import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { actions } from '@store/actions';
import { ThunkGetTenantCountry } from '@store/slices/tenant/thunks';
import { ThunkGetCrmDefaultsExtended } from '@store/slices/crm/thunks';
import {
  ThunkEditHelpdeskConfiguration,
  ThunkGetAiAgentTypes,
  ThunkGetHelpdeskLanguages,
} from '@store/slices/helpdeskAI/thunks';

import Loading from '@common/Loading/Loading';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import PhoneField from '@common/PhoneField';
import DropDown from '@components/Auth/Common/DropDown';
import ReusableButton from '@common/Button/Button';
import FormSwitch from '@components/Auth/Common/FormSwitch';
import Helper from '@ui/common/Helper';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconPlus from '@assets/icons/plusGrey.svg';
import TemplateMenu from '@components/Ticketing/Notifications/components/TemplateMenu';
import {
  aiMessageVariables,
  HELP_ICON_TOOLTIP,
  helpdeskGenders,
  KB_AI_ASSIST,
  notesTypes,
} from '../constants';
import { usePhoneInitialData } from '../../../../../hooks/usePhoneInitialData';
import { useCountryCodesOptions } from '../../../../../hooks/useCountryCodesOptions';
import { helpdeskAIValidation } from '../helpers';

import useStyles from '../styles';

const EditHelpdeskConfiguration = ({ open = true, onClose, data }) => {
  const [loading, setLoading] = useState(false);
  const [crmOptions, setCrmOptions] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [tenantCountryCode, setTenantCountryCode] = useState('US');
  const [languages, setLanguages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputName, setInputName] = useState('');
  const [agentTypes, setAgentTypes] = useState([]);

  const classes = useStyles({ loading });
  const sharedStyles = useSharedStyles();
  const dispatch = useDispatch();

  const { countryCodesOptions, countries } = useCountryCodesOptions();

  const defaultTenantCodes = {
    tenantCountryCode:
      countries.find(i => i.code === tenantCountryCode)?.code ?? 'US',
    tenantDialCode:
      countries.find(i => i.code === tenantCountryCode)?.dialCode ?? '+1',
  };

  const {
    initialIcon: callTransferIcon,
    nationalNumber: callTransferPhoneNumber,
    countryCodeFormValue: callTransferPhoneNumberCountryCode,
  } = usePhoneInitialData({
    phone: data.callTransferPhoneNumber,
    countries,
    defaultTenantCodes: !data.callTransferPhoneNumber
      ? defaultTenantCodes
      : {
          tenantCountryCode:
            parsePhoneNumberFromString(data.callTransferPhoneNumber).country ??
            'US',
          tenantDialCode:
            `+${
              parsePhoneNumberFromString(data.callTransferPhoneNumber)
                .countryCallingCode
            }` ?? '+1',
        },
  });

  const openVariablesClick = (target, name) => {
    setAnchorEl(target);
    setInputName(name);
  };

  const submit = values => {
    const {
      callTransferPhoneNumberCountryCode: redundantValue,
      ...rest
    } = values;
    const payload = {
      ...rest,
      callTransferPhoneNumber: values?.callTransferPhoneNumber
        ? values.callTransferPhoneNumberCountryCode.split(' ')[1] +
          values.callTransferPhoneNumber.replace(/[^0-9]/g, '')
        : '',
      isCallRecordingEnabled: values.addCallRecordingAsInternalNote !== 0,
      addCallRecordingAsInternalNote:
        values.addCallRecordingAsInternalNote !== 0 &&
        values.addCallRecordingAsInternalNote === 1,
    };

    setProcessing(true);
    dispatch(ThunkEditHelpdeskConfiguration(payload))
      .unwrap()
      .then(res => {
        dispatch(actions.updateItemInList(res));
        onClose();
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    Promise.all([
      dispatch(ThunkGetCrmDefaultsExtended()).unwrap(),
      dispatch(ThunkGetTenantCountry()).unwrap(),
      dispatch(ThunkGetHelpdeskLanguages()).unwrap(),
      dispatch(ThunkGetAiAgentTypes()).unwrap(),
    ])
      .then(
        ([
          defaultOptions,
          tenantCountry,
          languagesOptions,
          agentTypesAutocomplete,
        ]) => {
          setCrmOptions(defaultOptions);
          setTenantCountryCode(tenantCountry.data);
          setLanguages(languagesOptions);
          setAgentTypes(agentTypesAutocomplete);
        },
      )
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.dialogHeader}>
        Edit Helpdesk AI Assistant Configuration
        <Helper link={KB_AI_ASSIST} tooltip={HELP_ICON_TOOLTIP} />
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <Form
          onSubmit={submit}
          initialValues={{
            ...data,
            name: data.agentName,
            callTransferPhoneNumber,
            callTransferPhoneNumberCountryCode,
            // eslint-disable-next-line no-nested-ternary
            addCallRecordingAsInternalNote: data.isCallRecordingEnabled
              ? data?.addCallRecordingAsInternalNote
                ? 1
                : 2
              : 0,
          }}
          validate={helpdeskAIValidation}
          render={({ handleSubmit, values, form }) =>
            loading ? (
              <Loading />
            ) : (
              <form onSubmit={handleSubmit} className={classes.form}>
                <FieldWrapper
                  label="Enabled"
                  labelSize={11}
                  contentSize={1}
                  content={
                    <Field
                      name="isActive"
                      id="aiAssist-edit-isActive"
                      render={FormSwitch}
                      disabled={processing}
                      wrapperStyles={{ border: 'none' }}
                      checked={values.isActive}
                    />
                  }
                />

                <FieldWrapper
                  label="Agent Name"
                  labelSize={12}
                  contentSize={12}
                  isRequired
                  content={
                    <Field
                      id="aiAssist-edit-agentName"
                      name="agentName"
                      component={Input}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Agent Type"
                  labelSize={12}
                  contentSize={12}
                  isRequired
                  content={
                    <Field
                      id="aiAssist-add-typeId"
                      name="typeId"
                      component={DropDown}
                      options={agentTypes}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Company Name"
                  labelSize={12}
                  contentSize={12}
                  isRequired
                  content={
                    <Field
                      id="aiAssist-edit-companyName"
                      name="companyName"
                      component={Input}
                      disabled={processing}
                    />
                  }
                />

                <div className={classes.column}>
                  <FieldWrapper
                    label="Gender"
                    labelSize={4}
                    contentSize={8}
                    isRequired
                    alignItems="center"
                    content={
                      <Field
                        id="aiAssist-edit-gender"
                        name="voiceType"
                        component={DropDown}
                        options={helpdeskGenders}
                        disabled={processing}
                      />
                    }
                  />

                  <FieldWrapper
                    label="Language"
                    labelSize={4}
                    contentSize={8}
                    isRequired
                    alignItems="center"
                    content={
                      <Field
                        id="aiAssist-edit-language"
                        name="language"
                        component={DropDown}
                        options={languages}
                        disabled={processing}
                        required
                      />
                    }
                  />
                </div>

                <FieldWrapper
                  label="Greeting Message"
                  labelSize={12}
                  contentSize={12}
                  isRequired
                  content={
                    <div className={classes.templateInput}>
                      <Field
                        id="aiAssist-edit-greeting-message"
                        name="introMessage"
                        component={Input}
                        disabled={processing}
                        multiline
                        minRows={1}
                        maxRows={5}
                      />
                      <ActionButton
                        handler={event =>
                          openVariablesClick(
                            event.currentTarget,
                            'introMessage',
                          )
                        }
                        icon={<img src={iconPlus} alt="iconPlus" />}
                        toolTip="Add template variable"
                        disabled={processing}
                        classNameWrapper={classes.icon}
                      />
                    </div>
                  }
                />
                <FieldWrapper
                  label="Closing Message"
                  labelSize={12}
                  contentSize={12}
                  isRequired
                  content={
                    <div className={classes.templateInput}>
                      <Field
                        id="aiAssist-add-closing-message"
                        name="outroMessage"
                        component={Input}
                        disabled={processing}
                        multiline
                        minRows={1}
                        maxRows={5}
                      />
                      <ActionButton
                        handler={event =>
                          openVariablesClick(
                            event.currentTarget,
                            'outroMessage',
                          )
                        }
                        icon={<img src={iconPlus} alt="iconPlus" />}
                        toolTip="Add template variable"
                        disabled={processing}
                        classNameWrapper={classes.icon}
                      />
                    </div>
                  }
                />

                <FieldWrapper
                  label="Incoming Phone Number"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  content={
                    <Field
                      name="incomingPhoneNumber"
                      id="aiAssist-edit-incomingPhoneNumber"
                      render={Input}
                      disabled
                    />
                  }
                />
                <PhoneField
                  label="Call Transfer Phone Number"
                  phoneName="callTransferPhoneNumber"
                  phoneCodeName="callTransferPhoneNumberCountryCode"
                  countryCodesOptions={countryCodesOptions}
                  initialIcon={callTransferIcon}
                  isRequired
                  disabled={processing}
                  id="aiAssist-edit-callTransferPhoneNumber"
                />

                <FieldWrapper
                  label="Ticket Properties"
                  labelSize={12}
                  contentSize={12}
                  isRequired
                  content={
                    <Field
                      id="aiAssist-edit-crmTicketDefaultId"
                      name="crmTicketDefaultId"
                      component={DropDown}
                      options={crmOptions}
                      disabled={processing}
                      labelName="defaultName"
                      valueName="defaultId"
                    />
                  }
                />

                <FieldWrapper
                  label="Save recording as"
                  labelSize={6}
                  contentSize={6}
                  content={
                    <Field
                      name="addCallRecordingAsInternalNote"
                      id="aiAssist-edit-addCallRecordingAsInternalNote"
                      disabled={processing}
                      options={notesTypes}
                      component={DropDown}
                    />
                  }
                />

                <div className={sharedStyles.dialogActionsContainer}>
                  <ReusableButton
                    label="Close"
                    onClick={onClose}
                    disabled={processing}
                    id="aiAssist-edit-closeButton"
                  />
                  <ReusableButton
                    label="Update"
                    type="submit"
                    viewType="blue"
                    loading={processing}
                    disabled={processing}
                    id="aiAssist-edit-updateButton"
                  />
                </div>
                <TemplateMenu
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  form={form}
                  name={inputName}
                  templateVariables={aiMessageVariables}
                />
              </form>
            )
          }
        />
      </DialogContent>
    </Dialog>
  );
};

EditHelpdeskConfiguration.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.shape({
    name: PropTypes.string,
    incomingPhoneNumber: PropTypes.string,
    callTransferPhoneNumber: PropTypes.string,
    crmTicketDefaultId: PropTypes.string,
  }),
};

export default EditHelpdeskConfiguration;
