import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import HaloTicketTableActions from '@components/PsaTickets/components/Halo/HaloTicketTableActions';
import {
  ThunkGetHaloClientsByDefaultId,
  ThunkGetHaloSites,
  ThunkGetHaloTeams,
  ThunkGetHaloTicketStatuses,
  ThunkGetHaloTicketTypes,
  ThunkGetHaloUsers,
} from '@store/slices/HaloTickets/thunks';

const HaloTicketTable = ({ defaultId, crmId }) => {
  const dispatch = useDispatch();

  const [options, setOptions] = useState({
    statusOptions: [],
    typesOptions: [],
    clientsOptions: [],
    sitesOptions: [],
    usersOptions: [],
    teamsOptions: [],
  });

  const handleTicketIdClick = useCallback(ticketLink => {
    window.open(ticketLink, '_blank');
  }, []);

  const onMount = useCallback(async () => {
    await Promise.all([
      new Promise(res =>
        res(dispatch(ThunkGetHaloTicketStatuses(defaultId)).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetHaloTicketTypes(defaultId)).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetHaloClientsByDefaultId(defaultId)).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetHaloSites({ crmId: defaultId })).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetHaloUsers({ crmId: defaultId })).unwrap()),
      ),
      new Promise(res => res(dispatch(ThunkGetHaloTeams(defaultId)).unwrap())),
    ]).then(res => {
      setOptions({
        statusOptions: res[0],
        typesOptions: res[1],
        clientsOptions: res[2],
        sitesOptions: res[3],
        usersOptions: res[4],
        teamsOptions: res[5],
      });
    });
  }, [dispatch, defaultId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const columns = [
    {
      name: 'Id',
      key: 'id',
      isCustomAccessor: true,
      accessor: item => (
        <div
          style={{ color: 'blue', cursor: 'pointer' }}
          onClick={() => {
            handleTicketIdClick(item.ticketLink);
          }}
          role="presentation"
        >
          {item.id}
        </div>
      ),
      searchable: true,
      sortable: true,
    },
    {
      name: 'Summary',
      key: 'summary',
      searchable: true,
      sortable: true,
    },
    {
      name: 'Status',
      key: 'statusName',
      filterByAutocomplete: 'statusId',
      sortName: 'statusId',
      searchable: true,
      sortable: true,
      type: TableTypes.dropdown,
      dropDownValues: options.statusOptions,
    },
    {
      name: 'Type',
      key: 'ticketTypeName',
      filterByAutocomplete: 'ticketTypeId',
      sortName: 'ticketTypeId',
      searchable: true,
      sortable: true,
      type: TableTypes.dropdown,
      dropDownValues: options.typesOptions,
    },
    {
      name: 'Customer',
      key: 'clientName',
      filterByAutocomplete: 'clientId',
      sortName: 'clientId',
      searchable: true,
      sortable: true,
      type: TableTypes.dropdown,
      dropDownValues: options.clientsOptions,
    },
    {
      name: 'Site',
      key: 'siteName',
      filterByAutocomplete: 'siteId',
      sortName: 'siteId',
      searchable: true,
      sortable: true,
      type: TableTypes.dropdown,
      dropDownValues: options.sitesOptions,
    },
    {
      name: 'User',
      key: 'userName',
      filterByAutocomplete: 'userId',
      searchable: true,
      sortable: true,
      type: TableTypes.dropdown,
      dropDownValues: options.usersOptions,
    },
    {
      name: 'Team',
      key: 'team',
      filterByAutocomplete: 'teamId',
      sortName: 'teamId',
      searchable: true,
      sortable: true,
      type: TableTypes.dropdown,
      dropDownValues: options.teamsOptions,
    },
    {
      name: 'Actions',
      key: TableTypes.actions,
      type: TableTypes.actions,
      minWidth: 165,
      width: 165,
      accessor: record => (
        <HaloTicketTableActions
          record={record}
          crmDefaultId={defaultId}
          crmId={crmId}
        />
      ),
    },
  ];

  return (
    <Table
      resource={`TicketUpdate/halo/${defaultId}/tickets`}
      columns={columns}
      defaultSort={{ fieldName: 'id', order: 'DESC' }}
    />
  );
};

HaloTicketTable.propTypes = {
  defaultId: PropTypes.number,
  crmId: PropTypes.number.isRequired,
};

export default HaloTicketTable;
