import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { TableTypes } from '@components/Table/constants';
import Table from '@components/Table';
import {
  ThunkGetSuperOpsCompaniesByDefaultId,
  ThunkGetSuperOpsStatuses,
  ThunkGetSuperOpsTypes,
} from '@store/slices/SuperOps/thunks';
import SuperOpsTicketTableActions from './SuperOpsTicketTableActions';

import useStyles from '../../styles';

const SuperOpsTicketTable = ({ defaultId, crmId }) => {
  const classes = useStyles();

  const handleTicketIdClick = useCallback(ticketLink => {
    window.open(ticketLink, '_blank');
  }, []);

  const [options, setOptions] = useState({
    typesOptions: [],
    statusesOptions: [],
    companiesOptions: [],
  });

  const dispatch = useDispatch();

  const columns = useMemo(
    () => [
      {
        name: 'Display ID',
        key: 'displayId',
        sortable: true,
        searchable: true,
        isCustomAccessor: true,
        accessor: item => (
          <div
            className={classes.link}
            onClick={() => {
              handleTicketIdClick(item.ticketLink);
            }}
            role="presentation"
          >
            {item.displayId}
          </div>
        ),
      },
      {
        name: 'Subject',
        sortable: true,
        searchable: true,
        key: 'subject',
      },
      {
        name: 'Ticket Type',
        key: 'ticketType',
        sortable: true,
        searchable: true,
        minWidth: 200,
        type: TableTypes.dropdown,
        dropDownValues: options.typesOptions,
        filterByAutocomplete: 'ticketType',
      },
      {
        name: 'Status',
        key: 'status',
        sortable: true,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: options.statusesOptions,
        filterByAutocomplete: 'statusId',
      },
      {
        name: 'Client',
        key: 'client.name',
        isCustomAccessor: true,
        accessor: item => item?.client?.name,
        sortable: true,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: options.companiesOptions,
        filterByAutocomplete: 'clientId',
      },
      {
        name: 'Requester',
        key: 'requester.name',
        isCustomAccessor: true,
        accessor: item => item?.requester?.name,
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        width: 150,
        accessor: record => (
          <SuperOpsTicketTableActions
            record={record}
            crmDefaultId={defaultId}
            crmId={crmId}
          />
        ),
      },
    ],
    [classes.link, crmId, defaultId, handleTicketIdClick, options],
  );

  const onMount = useCallback(() => {
    Promise.all([
      dispatch(ThunkGetSuperOpsTypes(defaultId)).unwrap(),
      dispatch(ThunkGetSuperOpsStatuses(defaultId)).unwrap(),
      dispatch(ThunkGetSuperOpsCompaniesByDefaultId(defaultId)).unwrap(),
    ]).then(res => {
      const responseOptions = {
        typesOptions: res[0].map(i => ({ value: i.label, label: i.label })),
        statusesOptions: res[1],
        companiesOptions: res[2],
      };

      setOptions(responseOptions);
    });
  }, [defaultId, dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Table
      columns={columns}
      resource={`TicketUpdate/superOps/${defaultId}/tickets`}
      defaultSort={{ fieldName: 'displayId', order: 'DESC' }}
    />
  );
};

SuperOpsTicketTable.propTypes = {
  defaultId: PropTypes.number.isRequired,
  crmId: PropTypes.number.isRequired,
};

export default SuperOpsTicketTable;
