import makeStyles from '@material-ui/core/es/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  bulkActionWrapper: {
    display: 'flex',
    gap: '8px',
  },
  buttonWrapper: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export default useStyles;
