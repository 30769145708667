import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '20px',
    padding: '20px',
    maxHeight: '80vh',
    overflowY: 'auto',
    background: 'white',
    borderRadius: '15px',
    '& > div': {
      margin: '20px 0px',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: '10px',
    },
  },
  table: {
    '& table': {
      maxHeight: '74vh',
    },
  },
  headerButton: {
    minWidth: '7rem',
  },
  componentsContainer: {
    display: 'flex',
    marginTop: '1.5rem',
    alignItems: 'center',
    gap: '0.5rem',
  },
  title: {
    fontSize: '1.5rem',
    marginBottom: '0.25rem',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    marginTop: '1rem',
  },
  regionDropDown: {
    width: '7rem',
  },
}));

export default useStyles;
